"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.USED_PROTECTION_PLAN_TYPES = exports.NEW_PROTECTION_PLAN_TYPES = exports.PROTECTION_PLAN_TYPE_MAPPING = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
exports.PROTECTION_PLAN_TYPE_MAPPING = (_a = {},
    _a["".concat(enums_1.Condition.NEW).concat(enums_1.FinanceType.FINANCE)] = enums_1.ProtectionPlanType.NEW_FINANCE,
    _a["".concat(enums_1.Condition.NEW).concat(enums_1.FinanceType.CASH)] = enums_1.ProtectionPlanType.NEW_CASH,
    _a["".concat(enums_1.Condition.USED).concat(enums_1.FinanceType.FINANCE)] = enums_1.ProtectionPlanType.PREOWNED_FINANCE,
    _a["".concat(enums_1.Condition.USED).concat(enums_1.FinanceType.CASH)] = enums_1.ProtectionPlanType.PREOWNED_CASH,
    _a);
exports.NEW_PROTECTION_PLAN_TYPES = [
    enums_1.ProtectionPlanType.NEW_FINANCE,
    enums_1.ProtectionPlanType.NEW_CASH,
];
exports.USED_PROTECTION_PLAN_TYPES = [
    enums_1.ProtectionPlanType.PREOWNED_FINANCE,
    enums_1.ProtectionPlanType.PREOWNED_CASH,
];
