import { useBoatyard } from '../../../hooks/useBoatyard';

export const useBoatyardAnalyticsParams = () => {
  const boatyard = useBoatyard();

  if (!boatyard?.vessel?.defaults) return undefined;

  const {
    year,
    make,
    model,
    condition,
    basePrice,
    dealerPrice,
    imageUrl,
    hin,
    stockNumber,
  } = boatyard.vessel.defaults;

  return {
    deal_year: year,
    deal_make: make,
    deal_model: model,
    deal_condition: condition,
    deal_vessel_msrp: basePrice,
    deal_vessel_dealer_price: dealerPrice,
    deal_image_url: imageUrl,
    deal_hin: hin || null,
    deal_stock_no: stockNumber || null,
  };
};
