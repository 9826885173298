import { InventoryVesselFull } from '@digital-motors-boatyard/common/dist/apis';
import {
  DealSheetPricing,
  DealSheetVehicle,
} from '@digital-motors-boatyard/common/dist/interfaces';

import { USED } from '../../../constants';

export const getPartialVehicleFromVessel = (
  vessel: InventoryVesselFull
): Partial<DealSheetVehicle> => {
  const {
    id: inventoryId,
    hin,
    stockNumber,
    condition,
    isModel,
    year,
    make,
    makeId,
    model,
    modelId,
    category,
  } = vessel;

  return {
    ...(hin ? { hin } : null),
    inventoryId,
    stockNumber,
    condition,
    isModel,
    year,
    make,
    model,
    ...(makeId ? { makeId } : null),
    ...(modelId ? { modelId } : null),
    category,
    vesselClass: vessel.class,
    ...(vessel.engineConfiguration?.engines.length
      ? {
          totalEngines: vessel.engineConfiguration.engines.length,
          totalHorsepower: vessel.engineConfiguration.engines.reduce(
            (total, engine) => (total += engine.horsepower || 0),
            0
          ),
        }
      : null),
  };
};

export const getPartialPricingFromVessel = (
  vessel: InventoryVesselFull
): Partial<DealSheetPricing> => {
  const { condition } = vessel;

  return {
    dealerPrice: vessel.dealerPrice?.amount,
    dmSellingPrice: vessel.dmSellingPrice?.amount,
    salesPrice: vessel.dmSellingPrice?.amount,
    ...(condition === USED
      ? { retailPrice: vessel.retailPrice?.amount }
      : {
          msrp: vessel.startingMsrp?.amount || vessel.totalMsrp?.amount,
          totalMsrp: vessel.totalMsrp?.amount,
        }),
  };
};
