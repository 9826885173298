import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { InventoryVesselFull } from '@digital-motors-boatyard/common/dist/apis';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';

import { extractData } from './extractData';

export const getVessel = createApiRequestHandler({
  request: async (identifier: string, dealerId: string) => {
    let vesselId = identifier;

    // If identifier is not a UUID4, check if it is a HIN/Stock Number
    if (!identifier.match(/^[a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12}$/)) {
      const searchRes = await axiosInstance.get(
        '/api/inventory/inventory-vessels',
        {
          params: {
            hinOrStockNumber: identifier,
            active: true,
            limit: 1,
            dealerId,
          },
        }
      );
      if (searchRes.status === 200 && searchRes.data.data.length) {
        vesselId = searchRes.data.data[0].id;
      }
    }
    if (!vesselId) return { status: 404 };

    return axiosInstance.get(
      `/api/inventory/inventory-vessels/${vesselId}/full`
    );
  },
  validateData: (data: InventoryVesselFull) => {
    return !!data;
  },
  extractData,
});
