"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectModel = void 0;
var constants_1 = require("@digital-motors-boatyard/common-frontend/dist/constants");
var components_form_1 = require("@digital-motors-boatyard/components.form");
var ui_input_1 = require("@digital-motors-boatyard/ui.input");
var ui_spinner_1 = require("@digital-motors-boatyard/ui.spinner");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var react_1 = __importStar(require("react"));
var getMakesDropdown_1 = require("../api/getMakesDropdown");
var getModelsDropdown_1 = require("../api/getModelsDropdown");
var constants_2 = require("../constants");
var Tenant_1 = require("../context/Tenant");
var useIsInverted_1 = require("../hooks/useIsInverted");
var SearchInputWrapper = ui_theme_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n\n  > svg {\n    position: absolute;\n    top: ", ";\n    right: ", ";\n  }\n"], ["\n  position: relative;\n\n  > svg {\n    position: absolute;\n    top: ", ";\n    right: ", ";\n  }\n"])), ui_theme_1.spacing.xs, ui_theme_1.spacing.units(3));
var SelectModel = function (_a) {
    var defaults = _a.defaults, requireYear = _a.requireYear, requireMake = _a.requireMake, requireModel = _a.requireModel, requireAll = _a.requireAll;
    var isInverted = (0, useIsInverted_1.useIsInverted)();
    var registerField = (0, components_form_1.useForm)().registerField;
    var tokenFailed = (0, react_1.useRef)(false);
    var vertical = (0, Tenant_1.useTenant)().vertical;
    var _b = (0, react_1.useReducer)(function (current, updates) {
        return __assign(__assign({}, current), updates);
    }, {
        isFetchingMakes: false,
        isFetchingModels: false,
        year: (defaults === null || defaults === void 0 ? void 0 : defaults.year) ? String(defaults.year) : '',
        make: (defaults === null || defaults === void 0 ? void 0 : defaults.make)
            ? { name: defaults.make, id: defaults.makeId || '' }
            : null,
        model: (defaults === null || defaults === void 0 ? void 0 : defaults.model)
            ? { name: defaults.model, id: defaults.modelId || '' }
            : null,
        makes: [],
        models: [],
        noMakesFound: false,
    }), state = _b[0], setState = _b[1];
    var isFetchingMakes = state.isFetchingMakes, isFetchingModels = state.isFetchingModels, year = state.year, make = state.make, model = state.model, makes = state.makes, models = state.models, noMakesFound = state.noMakesFound;
    var fetchModels = (0, react_1.useCallback)(function (year, makeId) { return __awaiter(void 0, void 0, void 0, function () {
        var modelsRes, matcher_1, match;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setState({ isFetchingModels: true, models: [], model: undefined });
                    return [4 /*yield*/, (0, getModelsDropdown_1.getModelsDropdown)({
                            years: [year],
                            makeIds: [makeId],
                        })];
                case 1:
                    modelsRes = _a.sent();
                    if (modelsRes.type === constants_1.STATUS_SUCCESS) {
                        matcher_1 = (model === null || model === void 0 ? void 0 : model.name)
                            ? new RegExp("^".concat(model.name.replace(/[^a-z0-9]+/gi, ''), "$"), 'i')
                            : '';
                        match = matcher_1 &&
                            modelsRes.value.find(function (m) {
                                return m.name.replace(/[^a-z0-9]+/gi, '').match(matcher_1);
                            });
                        setState(__assign({ isFetchingModels: false, models: modelsRes.value }, (match ? { model: match } : null)));
                    }
                    else {
                        setState({ isFetchingModels: false });
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [model]);
    var onChangeMake = (0, react_1.useCallback)(function (value, input) {
        var matcher = new RegExp("^".concat(value.replace(/[^a-z0-9]+/gi, ''), "$"), 'i');
        var match = matcher &&
            makes.find(function (m) { return m.name.replace(/[^a-z0-9]+/gi, '').match(matcher); });
        var didChange = (match === null || match === void 0 ? void 0 : match.id) !== (make === null || make === void 0 ? void 0 : make.id);
        setState(__assign({ make: match || { id: '', name: value } }, (didChange ? { model: { id: '', name: '' } } : null)));
        if (match) {
            if (year && didChange)
                fetchModels(Number(year), match.id);
            if (input)
                input.value = match.name;
        }
    }, [fetchModels, make, makes, year]);
    var onChangeModel = (0, react_1.useCallback)(function (value, input) {
        var matcher = new RegExp("^".concat(value.replace(/[^a-z0-9]+/gi, ''), "$"), 'i');
        var match = matcher &&
            models.find(function (m) { return m.name.replace(/[^a-z0-9]+/gi, '').match(matcher); });
        setState({ model: match || { id: '', name: value } });
        if (input && match)
            input.value = match.name;
    }, [models]);
    // Fetch the makes
    (0, react_1.useEffect)(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var makesRes, matchString_1, selectedMake, defaultMake;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (isFetchingMakes ||
                            makes.length ||
                            tokenFailed.current ||
                            noMakesFound) {
                            return [2 /*return*/];
                        }
                        setState({ isFetchingMakes: true, makes: [], make: undefined });
                        return [4 /*yield*/, (0, getMakesDropdown_1.getMakesDropdown)(__assign({ vertical: vertical }, (year ? { years: [Number(year)] } : null)))];
                    case 1:
                        makesRes = _c.sent();
                        if (makesRes.type === constants_1.STATUS_SUCCESS) {
                            matchString_1 = (defaults === null || defaults === void 0 ? void 0 : defaults.make)
                                ? defaults.make.trim().replace(/[^a-z]/gi, '')
                                : '';
                            selectedMake = makesRes.value.find(function (make) { return make.name.replace(/[^a-z]/gi, '') === matchString_1; });
                            defaultMake = (defaults === null || defaults === void 0 ? void 0 : defaults.make)
                                ? { name: defaults.make, id: defaults.makeId || '' }
                                : null;
                            setState({
                                isFetchingMakes: false,
                                makes: makesRes.value,
                                make: selectedMake || defaultMake,
                                noMakesFound: !makesRes.value.length,
                            });
                        }
                        else {
                            // @ts-ignore
                            if (((_b = (_a = makesRes.error) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.status) === 401) {
                                tokenFailed.current = true;
                            }
                            setState({ isFetchingMakes: false });
                            return [2 /*return*/];
                        }
                        if ((defaults === null || defaults === void 0 ? void 0 : defaults.year) && (defaults === null || defaults === void 0 ? void 0 : defaults.makeId)) {
                            fetchModels(defaults.year, defaults.makeId);
                        }
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [
        defaults,
        fetchModels,
        isFetchingMakes,
        makes,
        noMakesFound,
        tokenFailed,
        vertical,
        year,
    ]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_form_1.SelectField, __assign({}, registerField('year', {
            onChange: function (e) {
                setState({ year: e.target.value, noMakesFound: false });
                if (make === null || make === void 0 ? void 0 : make.id)
                    fetchModels(Number(e.target.value), make.id);
            },
            validateOnChange: true,
            validators: requireAll || requireYear ? [components_form_1.required] : undefined,
        }), { "data-testid": "SelectModel__Year", label: "Year", inverted: isInverted, defaultValue: year || undefined, minifyLabel: !!year }),
            react_1.default.createElement("option", { value: "" }),
            constants_2.VESSEL_YEARS.map(function (year) { return (react_1.default.createElement("option", { key: year, value: year }, year)); })),
        react_1.default.createElement(SearchInputWrapper, null,
            react_1.default.createElement(ui_input_1.SearchInput, __assign({}, registerField('make', {
                validateOnChange: true,
                validators: requireAll || requireMake ? [components_form_1.required] : undefined,
                onChange: function (value) {
                    if (!isFetchingMakes)
                        onChangeMake(value);
                },
                onBlur: function (e) { return onChangeMake(e.target.value, e.target); },
            }), { onSelectOption: function (option) {
                    setState({ make: option, model: { id: '', name: '' } });
                }, "data-testid": "SelectModel__MakeName", key: "".concat(year, "_make_").concat(isFetchingMakes, "_").concat(makes.length), label: "Make", defaultValue: make === null || make === void 0 ? void 0 : make.name, disabled: !year || isFetchingMakes, options: makes, colorScope: "sidebars.rider", borderRadius: "s", minSearchLength: 2, muted: true })),
            isFetchingMakes && react_1.default.createElement(ui_spinner_1.Spinner, { size: 16 })),
        react_1.default.createElement("input", __assign({}, registerField('makeId', { nullable: true }), { type: "hidden", "data-testid": "SelectModel__MakeId", defaultValue: make === null || make === void 0 ? void 0 : make.id })),
        react_1.default.createElement(SearchInputWrapper, null,
            react_1.default.createElement(ui_input_1.SearchInput, __assign({}, registerField('model', {
                validateOnChange: true,
                validators: requireAll || requireModel ? [components_form_1.required] : undefined,
                onChange: function (value) {
                    if (!isFetchingModels)
                        onChangeModel(value);
                },
                onBlur: function (e) { return onChangeModel(e.target.value, e.target); },
            }), { onSelectOption: function (option) { return setState({ model: option }); }, "data-testid": "SelectModel__ModelName", key: "".concat(year, "_").concat(make === null || make === void 0 ? void 0 : make.name, "_model_").concat(isFetchingModels, "_").concat(models.length), label: "Model", defaultValue: model === null || model === void 0 ? void 0 : model.name, disabled: !year || !make || isFetchingModels, options: models, colorScope: "sidebars.rider", borderRadius: "s", minSearchLength: 2, muted: true })),
            isFetchingModels && react_1.default.createElement(ui_spinner_1.Spinner, { size: 16 })),
        react_1.default.createElement("input", __assign({}, registerField('modelId', { nullable: true }), { type: "hidden", "data-testid": "SelectModel__ModelId", defaultValue: model === null || model === void 0 ? void 0 : model.id }))));
};
exports.SelectModel = SelectModel;
var templateObject_1;
