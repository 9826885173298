/* eslint-disable @typescript-eslint/naming-convention */
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import UAParser from 'ua-parser-js';

import { useBoatyard } from '../../hooks/useBoatyard';
import { useAppData } from '../AppDataContext';
import { useUser } from '../User';
import { mapToTealiumEvent } from './tealium';
import {
  AnalyticsContextInterface,
  AnalyticsProviderProps,
  EventParams,
} from './types';

export const AnalyticsContext = createContext<
  undefined | AnalyticsContextInterface
>(undefined);

export const AnalyticsProvider: FC<AnalyticsProviderProps> = ({ children }) => {
  const { tenant, dealer } = useAppData();
  const user = useUser();
  const boatyard = useBoatyard();
  const [hasTealium, setHasTealium] = useState(
    // @ts-ignore
    typeof window !== 'undefined' && !!window.utag
  );

  const { os, device } = useMemo(() => {
    if (typeof window === 'undefined') {
      return { os: null, device: null };
    }
    const parser = new UAParser();
    return {
      os: parser.getOS(),
      device: parser.getDevice(),
    };
  }, []);

  const identifyParams = useMemo((): EventParams => {
    return {
      tenant_uuid: tenant.id,
      tenant_name: tenant.name,
      dealer_uuid: dealer.id,
      user_id: user.id,
    };
  }, [dealer.id, tenant.id, tenant.name, user.id]);

  const globalParams = useMemo((): EventParams => {
    return {
      referral_url: window?.location.origin, // The site the rider is launched on
      tenant_country: tenant.country,
      tenant_vertical: tenant.vertical,
      dealer_name: dealer.name,
      dealer_country: dealer.country,
      user_id: user?.id || null,
      user_zip: user?.zipcode || null,
      url: window.location.href,
      location: 'vtr',
      $os: os ? os.name : null,
      $device: device ? device.model : null,
      ...identifyParams,
    };
  }, [
    tenant.country,
    tenant.vertical,
    dealer.name,
    dealer.country,
    user.id,
    user.zipcode,
    os,
    device,
    identifyParams,
  ]);

  const trackEvent: AnalyticsContextInterface['trackEvent'] = useCallback(
    (eventName, params) => {
      const allParams = {
        ...globalParams,
        ...params,
      };
      const tealiumEvent = mapToTealiumEvent(eventName, allParams);
      if (!tealiumEvent) return;

      if (hasTealium) {
        // @ts-ignore
        window.utag.link(tealiumEvent);
      }
      const customAnalyticsCallback =
        boatyard?.analyticsCallbacks[tealiumEvent.tealium_event];
      if (customAnalyticsCallback) {
        customAnalyticsCallback(tealiumEvent);
      }
    },
    [boatyard, globalParams, hasTealium]
  );

  const context = useMemo(
    () => ({
      trackEvent,
      analyticsEnabled: hasTealium,
    }),
    [hasTealium, trackEvent]
  );

  const tealiumCheckMillis = useRef(250);
  useEffect(() => {
    if (hasTealium || typeof window === 'undefined') return;

    let checkTimeout: ReturnType<typeof setTimeout>;

    const checkForTealium = () => {
      // @ts-ignore
      if (window.utag) {
        setHasTealium(true);
      } else {
        checkTimeout = setTimeout(checkForTealium, tealiumCheckMillis.current);
        // back off a bit if we're not seeing it since it might not be being used here
        tealiumCheckMillis.current = Math.min(
          tealiumCheckMillis.current * 2,
          30000
        );
      }
    };

    checkForTealium();

    return () => {
      clearTimeout(checkTimeout);
    };
  }, [hasTealium]);

  return (
    <AnalyticsContext.Provider value={context}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error('useAnalytics must be used inside of a AnalyticsProvider!');
  }
  return context;
};
