import {
  Button as _Button,
  ButtonProps,
} from '@digital-motors-boatyard/ui.button';
import { Add, Close } from '@digital-motors-boatyard/ui.icons';
import { Text } from '@digital-motors-boatyard/ui.text';
import { shadows, spacing, styled } from '@digital-motors-boatyard/ui.theme';
import { FC, ReactNode } from 'react';

import { color } from '../lib/color';

interface PillButtonProps extends ButtonProps {
  outlined?: boolean;
  iconOnly?: boolean;
  htmlFor?: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Group = styled.div`
  background: ${color('divider')};
  border-radius: ${spacing.s};
  padding: ${spacing.xxxs};
  display: inline-flex;

  button + button {
    margin-left: ${spacing.xxxs};
  }
`;

export const Button = styled(_Button)<PillButtonProps>`
  ${(props) => {
    if (!props.active) return '';
    return `
      background: ${color('background')(props)} !important;
      box-shadow: ${shadows.s};
      cursor: default !important;
      pointer-events: none;
    `;
  }}
  ${(props) => {
    if (!props.outlined) return '';
    return `
      border-color: ${color('border')(props)};
    `;
  }}
  border-radius: ${spacing.s};

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    height: 12px;
    width: 12px;
    margin-right: ${spacing.xxxs};

    path {
      stroke-width: 3;
    }
  }

  ${(props) => {
    if (!props.iconOnly) return '';
    return `
      padding: 0;
      min-width: ${spacing.s};

      > span {
        display: inline-flex;
        align-self: center;
      }

      svg {
        margin-right: 0;
      }
    `;
  }}
`;
Button.defaultProps = {
  size: 's',
  variant: 'clear',
  type: 'button',
};

export const PillButtonGroup: FC<{ children: ReactNode }> = ({ children }) => (
  <Container>
    <Group>{children}</Group>
  </Container>
);

export const PillButton: FC<PillButtonProps> = ({ children, ...rest }) => (
  <Button {...rest}>
    <Text bold>{children}</Text>
  </Button>
);

export const AddButton: FC<PillButtonProps> = ({
  children = 'Add',
  ...rest
}) => (
  <PillButton size="s" {...rest}>
    <Add /> {!rest.iconOnly && children}
  </PillButton>
);

export const RemoveButton: FC<PillButtonProps> = ({
  children = 'Remove',
  ...rest
}) => (
  <PillButton size="s" {...rest}>
    <Close /> {!rest.iconOnly && children}
  </PillButton>
);
