import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { Vertical } from '@digital-motors-boatyard/common/dist/enums';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';

import { extractData } from './extractData';

interface Params {
  vertical: Vertical;
  year: number;
  make: string;
  model: string;
  oemCode?: string;
  engineManufacturer?: string;
}

interface Response {
  makeId: string | null;
  modelId: string | null;
  oemCode: string | null;
  engineManufacturerId: string | null;
}

export const getVesselAssociations = createApiRequestHandler({
  request: (params: Params) => {
    return axiosInstance.get('/api/vessels/utility/find-by-names', { params });
  },
  validateData: (data: Response) => Boolean(data),
  extractData,
});
