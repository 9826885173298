import { Country } from '@digital-motors-boatyard/common/dist/enums';
import {
  exactCharacterLength,
  FormattedField,
  isValidPhoneForCountry,
  useForm,
} from '@digital-motors-boatyard/components.form';
import { ModalHeader } from '@digital-motors-boatyard/components.modal';
import { phoneFormat } from '@digital-motors-boatyard/ui.input';
import { FC, useEffect, useRef, useState } from 'react';

import { sendPhoneValidation } from '../../api/sendPhoneValidation';
import { PHONE_HELPER_TEXT } from '../../constants';
import { useAnalytics } from '../../context/Analytics';
import { useBoatyardAnalyticsParams } from '../../context/Analytics/utils/getParamsFromBoatyard';
import { useAppData } from '../../context/AppDataContext';
import { useUser } from '../../context/User';
import { useBoatyard } from '../../hooks/useBoatyard';
import { ErrorMessage, FieldsWrapper } from '../../styles';
import { PillButton, PillButtonGroup } from '../PillButton';

interface Props {
  visible: boolean;
  dismiss?: () => void;
}

export const SignInForm: FC<Props> = ({ visible, dismiss }) => {
  const boatyard = useBoatyard();
  const { trackEvent } = useAnalytics();
  const analyticsParams = useBoatyardAnalyticsParams();
  const initialEventFired = useRef(false);
  const { setAuthModalView, setSignInPhone } = useUser();
  const { registerSubmitHandler, registerField, getValues, validate } =
    useForm<{
      phoneNumber: string;
    }>();
  const { tenant } = useAppData();
  const { id: tenantId, country } = tenant;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const onSubmit = registerSubmitHandler(async () => {
    const values = getValues();
    const isValid = validate(values);

    if (!values || !isValid) return;

    setIsSubmitting(true);
    setSubmissionError('');

    const res = await sendPhoneValidation({
      phoneNumber: values.phoneNumber,
      tenantId,
      country,
    });

    setSignInPhone(values.phoneNumber);
    trackEvent('rider_login_submit', analyticsParams);

    if (res.success) {
      setAuthModalView('verify');
    } else {
      setSubmissionError(res.errorMessage || 'An error occurred');
    }
    // If the phone number is not found, switch to the registration form
    if (res.errorType === 'notFound') {
      setAuthModalView('register');
    }
    setIsSubmitting(false);
  });

  useEffect(() => {
    if (!visible || initialEventFired.current) return;
    trackEvent('rider_login', analyticsParams);
    initialEventFired.current = true;
  }, [analyticsParams, trackEvent, visible]);

  return (
    <>
      <ModalHeader
        colorScope="sidebars.rider"
        title="Welcome"
        leftButtonText="Cancel"
        leftButtonAction={() => {
          dismiss ? dismiss() : boatyard?.closeRider();
          trackEvent('rider_login_close', analyticsParams);
        }}
        noLine
      />
      <PillButtonGroup>
        <PillButton
          data-testid="SignInForm__SignUp"
          onClick={() => setAuthModalView('register')}
        >
          Sign Up
        </PillButton>
        <PillButton data-testid="SignInForm__SignIn" active>
          Sign In
        </PillButton>
      </PillButtonGroup>
      <FieldsWrapper>
        <FormattedField
          {...registerField('phoneNumber', {
            dataType: 'numericString',
            validators: [
              exactCharacterLength(10, 'Invalid phone number'),
              isValidPhoneForCountry([
                Country.UNITED_STATES,
                Country.CANADA,
                country as Country,
              ]),
            ],
          })}
          {...phoneFormat}
          label="Mobile Number"
          data-testid="SignInForm__Phone"
          helperText={PHONE_HELPER_TEXT}
          readOnly={isSubmitting}
        />
      </FieldsWrapper>
      {submissionError && <ErrorMessage>{submissionError}</ErrorMessage>}
      <PillButton
        data-testid="SignInForm__Continue"
        variant="accent"
        onClick={onSubmit}
        disabled={isSubmitting}
      >
        Continue
      </PillButton>
    </>
  );
};
