import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { Country } from '@digital-motors-boatyard/common/dist/enums';
import { getIntlPhone } from '@digital-motors-boatyard/common-frontend/dist/utility/getIntlPhone';
import get from 'lodash/get';

interface UserVerificationPayload {
  phoneNumber: string;
  tenantId: string;
  country?: Country | null;
}

interface VerificationMessageSentResponse {
  success: boolean;
  errorType: 'notFound' | 'invalidNumber' | 'serverError' | null;
  errorMessage: string | null;
}

const USER_NOT_FOUND_ERROR = 'USE-PHONE_NUMBER_NOT_FOUND';

export const sendPhoneValidation = async ({
  phoneNumber,
  tenantId,
  country,
}: UserVerificationPayload): Promise<VerificationMessageSentResponse> => {
  try {
    const response = await axiosInstance.post('/api/users/users/auth/sms', {
      phoneNumber: getIntlPhone(country, phoneNumber),
      tenantId,
    });

    if (response.data.code === USER_NOT_FOUND_ERROR) {
      return {
        success: false,
        errorType: 'notFound',
        errorMessage: response.data.message,
      };
    }

    return {
      success: true,
      errorType: null,
      errorMessage: null,
    };
  } catch (err) {
    const status = get(err, 'response.status');

    if (status === 400) {
      return {
        success: false,
        errorType: 'invalidNumber',
        errorMessage: 'Phone number must be a valid phone number',
      };
    }

    if (status === 404) {
      return {
        success: false,
        errorType: 'notFound',
        errorMessage: 'Phone number not found',
      };
    }

    return {
      success: false,
      errorType: 'serverError',
      errorMessage:
        'There was an error sending your verification code. Please try again.',
    };
  }
};
