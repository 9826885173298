"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteReview = void 0;
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var react_1 = __importStar(require("react"));
var Tenant_1 = require("../context/Tenant");
var useIsInverted_1 = require("../hooks/useIsInverted");
var useScrollUpOnMount_1 = require("../hooks/useScrollUpOnMount");
var PaymentSummary_1 = require("./deal-builder/PaymentSummary");
var DealBreakdown_1 = require("./DealBreakdown");
var DealerInfo_1 = require("./DealerInfo");
var PillButton_1 = require("./PillButton");
var SecondaryCtaButton_1 = require("./SecondaryCtaButton");
var SectionHeader_1 = require("./SectionHeader");
var Buttons = ui_theme_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: ", " 0 0;\n\n  button + button {\n    margin-left: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: ", " 0 0;\n\n  button + button {\n    margin-left: ", ";\n  }\n"])), ui_theme_1.spacing.xs, ui_theme_1.spacing.xxxs);
var QuoteReview = function (_a) {
    var onEditDeal = _a.onEditDeal, onNextSteps = _a.onNextSteps;
    var configuration = (0, Tenant_1.useTenant)().configuration;
    var isInverted = (0, useIsInverted_1.useIsInverted)();
    (0, useScrollUpOnMount_1.useScrollUpOnMount)();
    var isSecondaryCtaEnabled = (0, react_1.useMemo)(function () {
        var _a, _b;
        return !!((_a = configuration === null || configuration === void 0 ? void 0 : configuration.secondaryCallToActionButton) === null || _a === void 0 ? void 0 : _a.text) &&
            !!((_b = configuration === null || configuration === void 0 ? void 0 : configuration.secondaryCallToActionButton) === null || _b === void 0 ? void 0 : _b.url);
    }, [configuration]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(SectionHeader_1.SectionHeader, { title: "Quote", stepText: "Step 2/2", onBack: onEditDeal }),
        react_1.default.createElement(PaymentSummary_1.PaymentSummary, null),
        react_1.default.createElement(DealBreakdown_1.DealBreakdown, null),
        react_1.default.createElement(Buttons, null,
            react_1.default.createElement(PillButton_1.PillButton, { "data-testid": "DealBreakdown__NextStepsButton", variant: "accent", inverted: isInverted, size: "m", onClick: onNextSteps }, "Next Steps"),
            isSecondaryCtaEnabled && react_1.default.createElement(SecondaryCtaButton_1.SecondaryCtaButton, null)),
        react_1.default.createElement(DealerInfo_1.DealerInfo, null)));
};
exports.QuoteReview = QuoteReview;
var templateObject_1;
