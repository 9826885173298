import { Theme } from '@digital-motors-boatyard/common/dist/interfaces';
import set from 'lodash/set';

type ValueType = 'string' | 'number' | 'boolean';

interface ColorOverride {
  cssVar: string;
  themeKey: string;
  valueType: ValueType;
}

// prettier-ignore
const colorOverrides: ColorOverride[] = [
  { cssVar: '--colors-global-dark', themeKey: 'global.black', valueType: 'string' },
  { cssVar: '--colors-global-light', themeKey: 'global.white', valueType: 'string' },
  { cssVar: '--colors-light-accent', themeKey: 'light.accent', valueType: 'string' },
  { cssVar: '--colors-light-heading', themeKey: 'light.heading', valueType: 'string' },
  { cssVar: '--colors-dark-accent', themeKey: 'dark.accent', valueType: 'string' },
  { cssVar: '--colors-dark-heading', themeKey: 'dark.heading', valueType: 'string' },
  { cssVar: '--colors-rider-body', themeKey: 'sidebars.rider.color', valueType: 'string' },
  { cssVar: '--colors-rider-inverted', themeKey: 'sidebars.rider.darkMode', valueType: 'boolean' },
  // other theme colors exist but are not currently used in the Rider
];

type TypographyGroup = 'body' | 'heading';

interface TypographyOverride {
  cssVar: string;
  group: TypographyGroup;
  themeKey: string;
  valueType: ValueType;
}

// prettier-ignore
const typographyOverrides: TypographyOverride[] = [
  { cssVar: '--typography-body-font-family', group: 'body', themeKey: 'family', valueType: 'string' },
  { cssVar: '--typography-body-letter-spacing', group: 'body', themeKey: 'spacing', valueType: 'string' },
  { cssVar: '--typography-body-font-weight', group: 'body', themeKey: 'weight', valueType: 'number' },
  { cssVar: '--typography-body-bold-font-weight', group: 'body', themeKey: 'boldWeight', valueType: 'number' },
  { cssVar: '--typography-body-text-transform', group: 'body', themeKey: 'transform', valueType: 'string' },
  { cssVar: '--typography-heading-font-family', group: 'heading', themeKey: 'family', valueType: 'string' },
  { cssVar: '--typography-heading-letter-spacing', group: 'heading', themeKey: 'spacing', valueType: 'string' },
  { cssVar: '--typography-heading-font-weight', group: 'heading', themeKey: 'weight', valueType: 'number' },
  { cssVar: '--typography-heading-text-transform', group: 'heading', themeKey: 'transform', valueType: 'string' },
];

// this groups up multiple typography setting so all members of a group can be changed with a single CSS variable
const typographyGroupMembers: { [group in TypographyGroup]: string[] } = {
  body: ['body', 'lead', 'caption', 'small', 'overline'],
  heading: [
    'heading1',
    'heading2',
    'heading3',
    'heading4',
    'heading5',
    'heading6',
  ],
};

const parseValue = (value: string, valueType: ValueType) => {
  switch (valueType) {
    case 'string':
      return value;
    case 'number':
      return Number(value);
    case 'boolean':
      return 'true' === value.toLowerCase();
  }
};

const getColorOverrides = (styles: CSSStyleDeclaration) => {
  const overrides = {};
  colorOverrides.forEach((override) => {
    const value = styles.getPropertyValue(override.cssVar)?.trim();
    if (value) {
      const parsedValue = parseValue(value, override.valueType);
      set(overrides, override.themeKey, parsedValue);
    }
  });
  return overrides as Theme['colors'];
};

const getTypographyOverrides = (styles: CSSStyleDeclaration) => {
  const overrides = {};
  typographyOverrides.forEach((override) => {
    const value = styles.getPropertyValue(override.cssVar)?.trim();
    if (value) {
      const parsedValue = parseValue(value, override.valueType);
      typographyGroupMembers[override.group].forEach((key) => {
        set(overrides, `${key}.${override.themeKey}`, parsedValue);
      });
    }
  });
  return overrides as Theme['typography'];
};

export const getThemeOverrides = (
  styles: CSSStyleDeclaration
): Partial<Theme> => {
  const typography = getTypographyOverrides(styles);
  const colors = getColorOverrides(styles);
  return { typography, colors };
};
