"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Disclaimer = exports.ControlWrapper = exports.ApplicationNumberWrapper = exports.Paragraph = exports.SectionHeader = exports.SectionWrapper = exports.Block = exports.LogoImage = exports.LogoWrapper = exports.LayoutWrapper = void 0;
var ui_text_1 = require("@digital-motors-boatyard/ui.text");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var color_1 = require("../../../lib/color");
var constants_1 = require("./constants");
exports.LayoutWrapper = ui_theme_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
exports.LogoWrapper = ui_theme_1.styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  margin-bottom: ", ";\n  padding: 20px 40px;\n"], ["\n  background-color: ", ";\n  margin-bottom: ", ";\n  padding: 20px 40px;\n"])), function (props) { return props.backgroundColor || (0, color_1.color)('bg05'); }, ui_theme_1.spacing.xs);
exports.LogoImage = ui_theme_1.styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  height: 50px;\n  margin: auto;\n"], ["\n  display: block;\n  height: 50px;\n  margin: auto;\n"])));
exports.LogoImage.defaultProps = {
    alt: 'provider-logo',
};
exports.Block = ui_theme_1.styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), ui_theme_1.spacing.xs);
exports.SectionWrapper = (0, ui_theme_1.styled)(exports.Block)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  grid-column-gap: ", ";\n  grid-template-columns: ", " 1fr;\n  background-color: ", ";\n"], ["\n  display: grid;\n  grid-column-gap: ", ";\n  grid-template-columns: ", " 1fr;\n  background-color: ", ";\n"])), ui_theme_1.spacing.xs, constants_1.LogoWidth, function (props) {
    return props.highlight ? (0, color_1.color)('bg05') : 'transparent';
});
exports.SectionHeader = (0, ui_theme_1.styled)(ui_text_1.Text)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: block;\n  margin-bottom: ", ";\n"], ["\n  display: block;\n  margin-bottom: ", ";\n"])), ui_theme_1.spacing.xxs);
exports.SectionHeader.defaultProps = {
    bold: true,
    variant: 'lead',
};
exports.Paragraph = (0, ui_theme_1.styled)(ui_text_1.Text)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: block;\n  margin: ", " 0;\n"], ["\n  display: block;\n  margin: ", " 0;\n"])), ui_theme_1.spacing.xxs);
exports.Paragraph.defaultProps = {
    variant: 'small',
};
exports.ApplicationNumberWrapper = (0, ui_theme_1.styled)(exports.Paragraph)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
exports.ControlWrapper = ui_theme_1.styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: grid;\n  grid-column-gap: ", ";\n  margin: ", " ", " 0;\n  justify-content: center;\n"], ["\n  display: grid;\n  grid-column-gap: ", ";\n  margin: ", " ", " 0;\n  justify-content: center;\n"])), ui_theme_1.spacing.xs, ui_theme_1.spacing.xs, ui_theme_1.spacing.xxs);
exports.Disclaimer = (0, ui_theme_1.styled)(exports.Paragraph)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), (0, color_1.color)('subtle'));
exports.Disclaimer.defaultProps = {
    variant: 'caption',
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
