import { TenantDisclosureInterface } from '@digital-motors-boatyard/common/dist/interfaces';
import React, { FC, useMemo } from 'react';
import { filterXSS } from 'xss';

import { Wrapper } from './styles';

interface Props {
  disclosure: TenantDisclosureInterface;
  appendOptional?: boolean;
}

export const Disclosure: FC<Props> = ({
  disclosure,
  appendOptional = false,
}) => {
  const text = useMemo(() => {
    // Admin appended <p> and </p> for other disclosures
    // We should at one point correct this behavior in admin
    const text = disclosure.description
      .replace('<p>', '')
      .replace('</p>', '')
      .trim();
    if (appendOptional) {
      return `${text} (optional)`;
    }

    return text;
  }, [appendOptional, disclosure]);

  const safeText = useMemo(() => {
    if (!text) return '';
    return filterXSS(text);
  }, [text]);

  if (!text) return null;
  return (
    <Wrapper
      data-testid="Disclosure__Wrapper"
      dangerouslySetInnerHTML={{ __html: safeText }}
    />
  );
};
