"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDefaultColorScope = exports.useIsInverted = void 0;
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var useIsInverted = function () {
    var theme = (0, ui_theme_1.useTheme)();
    return theme.colors.sidebars.rider.darkMode;
};
exports.useIsInverted = useIsInverted;
var useDefaultColorScope = function () {
    var isInverted = (0, exports.useIsInverted)();
    return isInverted ? 'dark' : 'light';
};
exports.useDefaultColorScope = useDefaultColorScope;
