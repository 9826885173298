"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgressBar = void 0;
var ui_text_1 = require("@digital-motors-boatyard/ui.text");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var react_1 = __importStar(require("react"));
var color_1 = require("../lib/color");
var Wrapper = ui_theme_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n"])));
var StepCircle = ui_theme_1.styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 35px;\n  height: 35px;\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  background: ", ";\n  border: 2px solid\n    ", ";\n  border-radius: ", ";\n"], ["\n  width: 35px;\n  height: 35px;\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  background: ", ";\n  border: 2px solid\n    ", ";\n  border-radius: ", ";\n"])), function (props) { return (0, color_1.color)(props.complete ? 'accent' : 'background'); }, function (props) { return (0, color_1.color)(props.complete || props.current ? 'accent' : 'disabled'); }, ui_theme_1.borderRadius.round);
var StepNumber = (0, ui_theme_1.styled)(ui_text_1.Text)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 5px;\n  color: ", ";\n"], ["\n  padding: 5px;\n  color: ", ";\n"])), function (props) {
    return (0, color_1.color)(props.complete ? 'background' : props.current ? 'accent' : 'disabled');
});
StepNumber.defaultProps = {
    bold: true,
};
var StepWrapper = ui_theme_1.styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])));
var StepName = (0, ui_theme_1.styled)(ui_text_1.Text)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  top: 40px;\n  color: ", ";\n  white-space: nowrap;\n"], ["\n  position: absolute;\n  top: 40px;\n  color: ", ";\n  white-space: nowrap;\n"])), function (props) {
    return (0, color_1.color)(props.complete || props.current ? 'accent' : 'disabled');
});
StepName.defaultProps = {
    variant: 'caption',
    bold: true,
};
var Connector = ui_theme_1.styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 2px;\n  width: 100%;\n  max-width: 200px;\n  background: ", ";\n"], ["\n  height: 2px;\n  width: 100%;\n  max-width: 200px;\n  background: ", ";\n"])), function (props) { return (0, color_1.color)(props.complete ? 'accent' : 'disabled'); });
var ProgressBar = function (_a) {
    var steps = _a.steps, currentStep = _a.currentStep, hideNames = _a.hideNames;
    var currentIndex = steps.findIndex(function (s) { return s === currentStep; });
    return (react_1.default.createElement(Wrapper, null, steps.map(function (step, i) {
        var state = {
            complete: i < currentIndex,
            current: i === currentIndex,
        };
        return (react_1.default.createElement(react_1.Fragment, { key: step },
            react_1.default.createElement(StepWrapper, { "data-testid": "ProgressBar__step" },
                react_1.default.createElement(StepCircle, __assign({}, state),
                    react_1.default.createElement(StepNumber, __assign({}, state), i + 1)),
                hideNames || react_1.default.createElement(StepName, __assign({}, state), step)),
            i < steps.length - 1 ? react_1.default.createElement(Connector, __assign({}, state)) : null));
    })));
};
exports.ProgressBar = ProgressBar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
