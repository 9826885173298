import { PaymentSummarySkeleton } from '@digital-motors-boatyard/by-vessel-rider.component';
import { spacing } from '@digital-motors-boatyard/ui.theme';
import { FC } from 'react';

import { RIDER_HEADER_HEIGHT } from '../../constants';
import {
  ExpandableGrid,
  VesselRiderContainer,
  VesselRiderContent,
} from '../../styles';
import { SkeletonBox, SkeletonPill } from '../Skeleton';

interface Props {
  isOpen: boolean;
}

export const RiderLoading: FC<Props> = ({ isOpen }) => (
  <VesselRiderContainer data-testid="RiderLoading__skeleton" isOpen={isOpen}>
    <SkeletonBox boxHeight={`${RIDER_HEADER_HEIGHT}px`} boxRadius="0" />
    <VesselRiderContent>
      <SkeletonPill />
      <PaymentSummarySkeleton />
      <ExpandableGrid>
        <SkeletonBox boxHeight={spacing.units(14)} />
        <SkeletonBox boxHeight={spacing.units(14)} />
        <SkeletonBox boxHeight={spacing.units(14)} />
        <SkeletonBox boxHeight={spacing.units(14)} />
      </ExpandableGrid>
    </VesselRiderContent>
  </VesselRiderContainer>
);
