import { DealSheetSummary } from '@digital-motors-boatyard/common/dist/interfaces';
import { FormProvider } from '@digital-motors-boatyard/components.form';
import { Modal } from '@digital-motors-boatyard/components.modal';
import { spacing } from '@digital-motors-boatyard/ui.theme';
import { FC, useMemo, useState } from 'react';

import { EventParams } from '../../context/Analytics';
import { PhoneAuthForm } from '../user-auth/PhoneAuthForm';
import { SignInForm } from './SignInForm';

export type ReEntryView = 'welcome' | 'verify';

interface Props {
  onDismiss: () => void;
  dealSummary: DealSheetSummary;
  isOpen: boolean;
}

export const ReEntryModal: FC<Props> = ({ onDismiss, dealSummary, isOpen }) => {
  const [view, setView] = useState<ReEntryView>('welcome');
  const [phone, setPhone] = useState('');

  const eventParams: EventParams = useMemo(
    () => ({
      /* eslint-disable @typescript-eslint/naming-convention */
      deal_condition: dealSummary.condition,
      deal_year: dealSummary.year.toString(),
      deal_make: dealSummary.make,
      deal_model: dealSummary.model,
      deal_hin: dealSummary.hin,
      deal_stock_no: dealSummary.stockNumber,
      deal_payment_type: dealSummary.financeType,
      deal_sales_price: dealSummary.salesPrice,
      deal_loan_apr: dealSummary.apr,
      deal_loan_downpayment: dealSummary.downPayment,
      deal_loan_term: dealSummary.term,
      deal_vessel_msrp: dealSummary.totalMsrp,
      deal_vessel_dealer_price: dealSummary.dmSellingPrice,
      deal_vessel_dm_selling_price: dealSummary.dmSellingPrice,
      /* eslint-enable @typescript-eslint/naming-convention */
    }),
    [dealSummary]
  );

  return (
    <Modal
      colorScope="sidebars.rider"
      innerPadding={spacing.xs}
      borderRadius={spacing.xxxs}
      isOpen={isOpen}
      data-testid="SignInForm__ReEntry__Modal"
      size="m"
      fullScreenMobile
      isCentered
      isInline
    >
      <div hidden={view === 'welcome' ? undefined : true}>
        <FormProvider<{ phoneNumber: string }> key="signin">
          <SignInForm
            setView={setView}
            setPhone={setPhone}
            onCancel={onDismiss}
            dealSummary={dealSummary}
            eventParams={eventParams}
            visible={view === 'welcome'}
          />
        </FormProvider>
      </div>
      <div hidden={view === 'verify' ? undefined : true}>
        <FormProvider<{ code: string }> key="verify">
          <PhoneAuthForm
            onClickBack={() => setView('welcome')}
            onSuccess={onDismiss}
            phoneNumber={phone}
            eventParams={eventParams}
            visible={view === 'verify'}
            isReEntry
          />
        </FormProvider>
      </div>
    </Modal>
  );
};
