import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { DealSheetSummary } from '@digital-motors-boatyard/common/dist/interfaces';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';

import { extractData } from './extractData';

export const getDealSheetSummary = createApiRequestHandler({
  request: (id: string) => {
    return axiosInstance.get(`/api/core/utility/dealsheet-summary/${id}`);
  },
  validateData: (data: DealSheetSummary) => Boolean(data),
  extractData,
});
