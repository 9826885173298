"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMessage = exports.ToggleButton = exports.ButtonWrapper = exports.ExpandableGrid = void 0;
var ui_button_1 = require("@digital-motors-boatyard/ui.button");
var ui_text_1 = require("@digital-motors-boatyard/ui.text");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var color_1 = require("./lib/color");
exports.ExpandableGrid = ui_theme_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-row-gap: ", ";\n"], ["\n  display: grid;\n  grid-row-gap: ", ";\n"])), ui_theme_1.spacing.xxs);
exports.ButtonWrapper = ui_theme_1.styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: ", ";\n\n  button {\n    border-radius: ", ";\n\n    + button {\n      margin-left: ", ";\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: ", ";\n\n  button {\n    border-radius: ", ";\n\n    + button {\n      margin-left: ", ";\n    }\n  }\n"])), ui_theme_1.spacing.xs, ui_theme_1.spacing.s, ui_theme_1.spacing.xxs);
exports.ToggleButton = (0, ui_theme_1.styled)(ui_button_1.Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0;\n\n  &,\n  :hover,\n  :focus,\n  :active,\n  :visited {\n    ", "\n    border: 2px solid\n      ", ";\n    border-radius: ", ";\n    box-shadow: none !important;\n    outline: none !important;\n    font-size: 14px;\n  }\n"], ["\n  padding: 0;\n\n  &,\n  :hover,\n  :focus,\n  :active,\n  :visited {\n    ", "\n    border: 2px solid\n      ", ";\n    border-radius: ", ";\n    box-shadow: none !important;\n    outline: none !important;\n    font-size: 14px;\n  }\n"])), function (props) {
    if (!props.selected) {
        return 'font-weight: normal;';
    }
    return "\n        background: transparent;\n        cursor: default;\n      ";
}, function (props) { return (0, color_1.color)(props.selected ? 'accent' : 'divider')(props); }, ui_theme_1.borderRadius.s);
exports.ToggleButton.defaultProps = {
    variant: 'clear',
};
exports.ErrorMessage = (0, ui_theme_1.styled)(ui_text_1.Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: block;\n  align-self: stretch;\n  margin: ", " 0 0;\n  padding: ", ";\n  color: ", ";\n  background: ", ";\n  border-radius: ", ";\n\n  + div,\n  + button {\n    margin-top: ", ";\n  }\n"], ["\n  display: block;\n  align-self: stretch;\n  margin: ", " 0 0;\n  padding: ", ";\n  color: ", ";\n  background: ", ";\n  border-radius: ", ";\n\n  + div,\n  + button {\n    margin-top: ", ";\n  }\n"])), ui_theme_1.spacing.xxs, ui_theme_1.spacing.xxs, (0, color_1.color)('background'), (0, color_1.color)('danger'), ui_theme_1.borderRadius.xs, ui_theme_1.spacing.xxs);
exports.ErrorMessage.defaultProps = {
    bold: true,
    as: 'mark',
    variant: 'small',
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
