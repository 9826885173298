import jwtDecode from 'jwt-decode';
import omit from 'lodash/omit';

import { JwtUserData, User } from '../types';

export const getUserFromJwt = (token: string): User | null => {
  try {
    const jwtUser = jwtDecode(token) as JwtUserData;

    if (!jwtUser) return null;

    return {
      ...omit(jwtUser, 'userId'),
      id: jwtUser.userId,
      isAuthenticated: true,
    };
  } catch {
    return null;
  }
};
