import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';

interface Params {
  refreshToken: string;
}

export interface TokenData {
  token: string;
  refreshToken: string;
}

export const refreshUserToken = createApiRequestHandler({
  request: (params: Params) => {
    return axiosInstance.post('/api/users/users/auth/refresh-token', params);
  },
  validateData: (data: TokenData) => data !== undefined,
});
