"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultFinanceCreditAppLayout = void 0;
var interfaces_1 = require("@digital-motors-boatyard/common/dist/interfaces");
exports.defaultFinanceCreditAppLayout = {
    pages: [
        {
            name: 'Info',
            forCoApplicant: false,
            sections: [
                {
                    title: 'Applicant Information',
                    type: interfaces_1.CreditAppSectionType.PERSONALINFO,
                    fields: [
                        interfaces_1.CreditAppField.APPLICANT_FIRST_NAME,
                        interfaces_1.CreditAppField.APPLICANT_LAST_NAME,
                        interfaces_1.CreditAppField.APPLICANT_MOBILE_PHONE,
                        interfaces_1.CreditAppField.APPLICANT_EMAIL,
                        interfaces_1.CreditAppField.APPLICANT_BIRTH_DATE,
                        interfaces_1.CreditAppField.APPLICANT_SSN,
                        interfaces_1.CreditAppField.APPLICANT_IS_CITIZEN,
                    ],
                },
            ],
        },
        {
            name: 'Residence',
            forCoApplicant: false,
            sections: [
                {
                    title: 'Residence',
                    type: interfaces_1.CreditAppSectionType.RESIDENCE,
                    minYearsHistory: 3,
                    fields: [
                        interfaces_1.CreditAppField.RESIDENCE_STATUS,
                        interfaces_1.CreditAppField.RESIDENCE_ADDRESS_LINE1,
                        interfaces_1.CreditAppField.RESIDENCE_ADDRESS_LINE2,
                        interfaces_1.CreditAppField.RESIDENCE_CITY,
                        interfaces_1.CreditAppField.RESIDENCE_REGION,
                        interfaces_1.CreditAppField.RESIDENCE_POSTAL_CODE,
                        interfaces_1.CreditAppField.RESIDENCE_MOVE_IN_DATE,
                        interfaces_1.CreditAppField.RESIDENCE_MONTHLY_PAYMENT,
                        interfaces_1.CreditAppField.RESIDENCE_LANDLORD_NAME,
                    ],
                },
            ],
        },
        {
            name: 'Employment',
            forCoApplicant: false,
            sections: [
                {
                    title: 'Employment',
                    type: interfaces_1.CreditAppSectionType.EMPLOYMENT,
                    minYearsHistory: 3,
                    fields: [
                        interfaces_1.CreditAppField.EMPLOYMENT_STATUS,
                        interfaces_1.CreditAppField.EMPLOYMENT_EMPLOYER_NAME,
                        interfaces_1.CreditAppField.EMPLOYMENT_EMPLOYER_PHONE,
                        interfaces_1.CreditAppField.EMPLOYMENT_JOB_TITLE,
                        interfaces_1.CreditAppField.EMPLOYMENT_START_DATE,
                        interfaces_1.CreditAppField.EMPLOYMENT_GROSS_ANNUAL_INCOME,
                    ],
                },
                {
                    title: 'Other Income',
                    type: interfaces_1.CreditAppSectionType.OTHER,
                    fields: [
                        interfaces_1.CreditAppField.OTHER_INCOME_TYPE,
                        interfaces_1.CreditAppField.OTHER_INCOME_GROSS_ANNUAL_INCOME,
                    ],
                },
            ],
        },
        {
            name: 'Banking',
            forCoApplicant: false,
            sections: [
                {
                    title: 'Bank Information',
                    type: interfaces_1.CreditAppSectionType.OTHER,
                    fields: [
                        interfaces_1.CreditAppField.CHECKING_BANK_NAME,
                        interfaces_1.CreditAppField.SAVINGS_BANK_NAME,
                    ],
                },
                {
                    title: 'Nearest Relative',
                    type: interfaces_1.CreditAppSectionType.RELATIVE,
                    fields: [
                        interfaces_1.CreditAppField.RELATIVE_FULL_NAME,
                        interfaces_1.CreditAppField.RELATIVE_ADDRESS_LINE1,
                        interfaces_1.CreditAppField.RELATIVE_ADDRESS_LINE2,
                        interfaces_1.CreditAppField.RELATIVE_CITY,
                        interfaces_1.CreditAppField.RELATIVE_REGION,
                        interfaces_1.CreditAppField.RELATIVE_POSTAL_CODE,
                        interfaces_1.CreditAppField.RELATIVE_PHONE,
                        interfaces_1.CreditAppField.RELATIVE_RELATIONSHIP,
                    ],
                },
            ],
        },
        {
            name: 'Co-applicant',
            forCoApplicant: true,
            sections: [
                {
                    title: 'Co-applicant Information',
                    type: interfaces_1.CreditAppSectionType.PERSONALINFO,
                    fields: [
                        interfaces_1.CreditAppField.APPLICANT_FIRST_NAME,
                        interfaces_1.CreditAppField.APPLICANT_LAST_NAME,
                        interfaces_1.CreditAppField.APPLICANT_MOBILE_PHONE,
                        interfaces_1.CreditAppField.APPLICANT_EMAIL,
                        interfaces_1.CreditAppField.APPLICANT_BIRTH_DATE,
                        interfaces_1.CreditAppField.APPLICANT_SSN,
                        interfaces_1.CreditAppField.APPLICANT_IS_CITIZEN,
                    ],
                },
                {
                    title: 'Co-applicant Residence',
                    type: interfaces_1.CreditAppSectionType.RESIDENCE,
                    minYearsHistory: 3,
                    fields: [
                        interfaces_1.CreditAppField.RESIDENCE_STATUS,
                        interfaces_1.CreditAppField.RESIDENCE_ADDRESS_LINE1,
                        interfaces_1.CreditAppField.RESIDENCE_ADDRESS_LINE2,
                        interfaces_1.CreditAppField.RESIDENCE_CITY,
                        interfaces_1.CreditAppField.RESIDENCE_REGION,
                        interfaces_1.CreditAppField.RESIDENCE_POSTAL_CODE,
                        interfaces_1.CreditAppField.RESIDENCE_MOVE_IN_DATE,
                        interfaces_1.CreditAppField.RESIDENCE_MONTHLY_PAYMENT,
                        interfaces_1.CreditAppField.RESIDENCE_LANDLORD_NAME,
                    ],
                },
                {
                    title: 'Co-applicant Employment',
                    type: interfaces_1.CreditAppSectionType.EMPLOYMENT,
                    minYearsHistory: 3,
                    fields: [
                        interfaces_1.CreditAppField.EMPLOYMENT_STATUS,
                        interfaces_1.CreditAppField.EMPLOYMENT_EMPLOYER_NAME,
                        interfaces_1.CreditAppField.EMPLOYMENT_EMPLOYER_PHONE,
                        interfaces_1.CreditAppField.EMPLOYMENT_JOB_TITLE,
                        interfaces_1.CreditAppField.EMPLOYMENT_START_DATE,
                        interfaces_1.CreditAppField.EMPLOYMENT_GROSS_ANNUAL_INCOME,
                    ],
                },
                {
                    title: 'Co-applicant Other Income',
                    type: interfaces_1.CreditAppSectionType.OTHER,
                    fields: [
                        interfaces_1.CreditAppField.OTHER_INCOME_TYPE,
                        interfaces_1.CreditAppField.OTHER_INCOME_GROSS_ANNUAL_INCOME,
                    ],
                },
                {
                    title: 'Co-applicant Bank Information',
                    type: interfaces_1.CreditAppSectionType.OTHER,
                    fields: [
                        interfaces_1.CreditAppField.CHECKING_BANK_NAME,
                        interfaces_1.CreditAppField.SAVINGS_BANK_NAME,
                    ],
                },
                {
                    title: 'Co-applicant Nearest Relative',
                    type: interfaces_1.CreditAppSectionType.RELATIVE,
                    fields: [
                        interfaces_1.CreditAppField.RELATIVE_FULL_NAME,
                        interfaces_1.CreditAppField.RELATIVE_ADDRESS_LINE1,
                        interfaces_1.CreditAppField.RELATIVE_ADDRESS_LINE2,
                        interfaces_1.CreditAppField.RELATIVE_CITY,
                        interfaces_1.CreditAppField.RELATIVE_REGION,
                        interfaces_1.CreditAppField.RELATIVE_POSTAL_CODE,
                        interfaces_1.CreditAppField.RELATIVE_PHONE,
                        interfaces_1.CreditAppField.RELATIVE_RELATIONSHIP,
                    ],
                },
            ],
        },
    ],
};
