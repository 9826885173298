"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHEFFIELD_CREDIT_APP_CONSENT_DISCLOSURES = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
exports.SHEFFIELD_CREDIT_APP_CONSENT_DISCLOSURES = [
    {
        locale: enums_1.Locale.AMERICAN_ENGLISH,
        name: 'Sheffield Credit App Consent',
        description: "<p>By checking this box you agree that you have read and acknowledge the following:</p>\n    <ul>\n      <li><a href=\"https://prequalify.sheffieldfinancial.com/Apply/PreQualApplication/disclosure/Agreement-to-Receive-Electronic-Communications\" target=\"_blank\">Agreement to Receive Electronic Communications</a></li>\n      <li><a href=\"https://prequalify.sheffieldfinancial.com/Apply/PreQualApplication/disclosure/Prequalification-General-Application-Disclosures\" target=\"_blank\">Prequalification General Application Disclosures</a></li>\n      <li><a href=\"https://prequalify.sheffieldfinancial.com/Apply/PreQualApplication/disclosure/COPPA-Disclosure\" target=\"_blank\">COPPA Disclosure</a></li>\n      <li><a href=\"https://www.truist.com/privacy\" target=\"_blank\">Truist Privacy</a></li>\n    </ul>",
        priority: 0,
        type: [enums_1.DisclosureType.NEW_FINANCE, enums_1.DisclosureType.PREOWNED_FINANCE],
        location: [enums_1.DisclosureLocation.CONSENT_CREDIT_APPLICATION],
    },
];
