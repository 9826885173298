import omitBy from 'lodash/omitBy';

import { EventParams, EventParamValue } from './types';

type UtagData = Record<string, string> | undefined;

// Note that "reentry" is mispelled as "reenrty" everywhere, and this has been carried along here
const tealiumEventNames: Record<string, string> = {
  rider_request_showing: 'dr_request_showing',
  rider_trade_in_add: 'dr_trade_in_added',
  rider_trade_in_photos_add: 'dr_trade_in_photos_added',
  rider_summary_done: 'dr_summary_done_click',
  rider_summary_submit_app: 'dr_credit_app_submit',
  rider_quote_submit: 'dr_get_quote_next_steps_click',
  rider_configure_submit: 'dr_get_quote_click',
  rider_lead_converted: 'dr_form_submit',
  rider_reenrty_verify_mobile_submit: 'dr_verify_mobile_submit',
  rider_reenrty_verify_mobile: 'dr_verify_mobile_load',
  rider_reenrty_submit: 'dr_reenrty_signin_submit',
  rider_reenrty: 'dr_reenrty_signin_load',
  rider_login_submit: 'dr_login_submit',
  rider_signup_submit: 'dr_signup_submit',
  rider_login_close: 'dr_login_close',
  rider_login: 'dr_login_load',
  rider_signup_prepopulated: 'dr_signup_pre_load',
  rider_signup: 'dr_signup_load',
  rider_signup_silent_failed: 'dr_signup_failure',
  rider_signup_silent: 'dr_signup_success',
  rider_close: 'dr_closed',
  rider_launch: 'dr_load',
  dr_reserve_now_payment_form_load: 'dr_reserve_now_payment_form_load',
  dr_reserve_now_payment_form_submit: 'dr_reserve_now_payment_form_submit',
  dr_reserve_now_payment_form_cancel: 'dr_reserve_now_payment_form_cancel',
  dr_reserve_now_payment_form_success: 'dr_reserve_now_payment_form_success',
  dr_reserve_now_payment_form_error: 'dr_reserve_now_payment_form_error',
  dr_get_prequalified_start: 'dr_get_prequalified_start',
  dr_get_prequalified_submit: 'dr_get_prequalified_submit',
  dr_get_prequalified_continue_app: 'dr_get_prequalified_continue_app',
};

const tealiumTagNames: Record<string, string> = {
  dealer_uuid: 'dr_dealer_guid',
  dealer_name: 'dr_dealer_name',
  tenant_uuid: 'dr_tenant_guid',
  tenant_name: 'dr_tenant_name',
  tenant_country: 'dr_tenant_country',
  user_id: 'dr_user_guid',
  user_zip: 'dr_user_zip',
  deal_vessel_uuid: 'dr_product_guid',
  deal_year: 'dr_product_year',
  deal_make: 'dr_product_make',
  deal_model: 'dr_product_model',
  deal_condition: 'dr_product_condition',
  deal_vessel_condition: 'dr_product_condition',
  deal_vessel_msrp: 'dr_product_msrp',
  deal_vessel_dealer_price: 'dr_product_dealer_price',
  deal_image_url: 'dr_product_image_url',
  deal_hin: 'dr_product_hin',
  deal_stock_no: 'dr_product_stock_no',
  deal_credit_tier: 'dr_credit_tier',
  deal_payment_type: 'dr_payment_type',
  deal_loan_apr: 'dr_loan_apr',
  deal_loan_downpayment: 'dr_loan_downpayment',
  deal_loan_term: 'dr_loan_term',
  trade_in_year: 'dr_trade_in_year',
  trade_in_make: 'dr_trade_in_make',
  trade_in_model: 'dr_trade_in_model',
  trade_in_condition: 'dr_trade_in_condition',
  trade_in_estimate: 'dr_trade_in_estimate',
  deal_uuid: 'dr_deal_guid',
  deal_discount_ids: 'dr_deal_discount_ids',
  deal_discount_names: 'dr_deal_discount_names',
  deal_discount_amounts: 'dr_deal_discount_amounts',
  request_showing_date: 'dr_request_showing_date',
  request_showing_time_of_day: 'dr_request_showing_time_of_day',
  referral_url: 'dr_referral_url',
};

const tealiumifyValue = (value: EventParamValue): string | undefined => {
  if (typeof value === 'string') return value;
  if (typeof value === 'number') return `${value}`;
  if (typeof value === 'boolean') return value ? '1' : '0';
};

const mapEventParams = (params: EventParams): EventParams => {
  return Object.entries(params).reduce((obj, [key, value]) => {
    const newKey = tealiumTagNames[key];
    const newValue = tealiumifyValue(value);
    return newKey && newValue ? { ...obj, [newKey]: newValue } : obj;
  }, {});
};

const getPageParams = (): EventParams => {
  // @ts-ignore
  const pageData = window && (window.utag_data as UtagData);
  return pageData
    ? omitBy(
        pageData,
        (value, key) => key.startsWith('ct.') || key.startsWith('ut.') // exclude Tealium internal data
      )
    : {};
};

export const mapToTealiumEvent = (
  eventName: string,
  params?: EventParams
): Record<string, string> | null => {
  const tealiumEvent = tealiumEventNames[eventName];
  if (!tealiumEvent) return null;

  const pageParams = getPageParams();
  const mappedParams = params ? mapEventParams(params) : {};
  return {
    ...pageParams,
    ...mappedParams,
    tealium_event: tealiumEvent,
  };
};

export const validTealiumEvents = Object.values(tealiumEventNames);
