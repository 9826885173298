import { useMemo } from 'react';

import { storage } from '../storage';

const STORAGE_PREFIX = 'rider__';

export interface StorageObject {
  set: (key: string, value: string, persist?: boolean) => void;
  get: (key: string) => string | null;
  delete: (key: string) => void;
}

const isValidKey = (key: string) => {
  return !!key.match(/^(token|refreshToken|refreshInterval|logout)__/);
};

export const useStorage = (): StorageObject => {
  return useMemo(() => {
    return {
      set: (key, value, persist) => {
        if (!isValidKey(key)) return;

        const storageKey = `${STORAGE_PREFIX}${key}`;

        if (persist && window?.localStorage) {
          window.localStorage.setItem(storageKey, value);
        }
        storage.set(storageKey, value);
      },
      get: (key) => {
        const storageKey = `${STORAGE_PREFIX}${key}`;

        return (
          window.localStorage?.getItem(storageKey) || storage.get(storageKey)
        );
      },
      delete: (key) => {
        const storageKey = `${STORAGE_PREFIX}${key}`;

        if (window?.localStorage && window.localStorage.getItem(storageKey)) {
          window.localStorage.removeItem(storageKey);
        }
        if (storage.has(storageKey)) {
          storage.delete(storageKey);
        }
      },
    };
  }, []);
};
