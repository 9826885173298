"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledSelectField = exports.Notice = void 0;
var ui_select_1 = require("@digital-motors-boatyard/ui.select");
var ui_text_1 = require("@digital-motors-boatyard/ui.text");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var color_1 = require("../../../lib/color");
exports.Notice = (0, ui_theme_1.styled)(ui_text_1.Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  margin: ", " 0;\n\n  p + p {\n    margin-top: ", ";\n  }\n"], ["\n  color: ", ";\n  margin: ", " 0;\n\n  p + p {\n    margin-top: ", ";\n  }\n"])), (0, color_1.color)('subtle'), ui_theme_1.spacing.xxs, ui_theme_1.spacing.xxs);
exports.Notice.defaultProps = {
    as: 'div',
    variant: 'caption',
};
exports.StyledSelectField = (0, ui_theme_1.styled)(ui_select_1.Select)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: ", ";\n"], ["\n  margin-top: ", ";\n"])), ui_theme_1.spacing.xxs);
var templateObject_1, templateObject_2;
