"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUser = exports.UserProvider = exports.UserContext = void 0;
var constants_1 = require("@digital-motors-boatyard/common-frontend/dist/constants");
var react_1 = __importStar(require("react"));
var getUserForToken_1 = require("../../api/getUserForToken");
var RiderComponent_1 = require("../RiderComponent");
var setAxiosAuthorizationHeader_1 = require("./utils/setAxiosAuthorizationHeader");
exports.UserContext = (0, react_1.createContext)({
    signInUser: function () { },
    isRegistered: false,
});
var UserProvider = function (_a) {
    var signInUser = _a.signInUser, initialUser = _a.initialUser, children = _a.children;
    var userData = (0, RiderComponent_1.useRiderApplication)().userData;
    var _b = (0, react_1.useState)(initialUser), user = _b[0], setUser = _b[1];
    (0, react_1.useEffect)(function () {
        // update API token whenever it changes and fetch related user data
        (0, setAxiosAuthorizationHeader_1.setAxiosAuthorizationHeader)(userData.token || null);
        if (userData.token) {
            (0, getUserForToken_1.getUserForToken)().then(function (result) {
                if (result.type === constants_1.STATUS_SUCCESS) {
                    setUser(__assign(__assign({}, result.value), { isAuthenticated: true }));
                }
            });
        }
        else {
            setUser(undefined);
        }
    }, [userData, userData.token]);
    var value = (0, react_1.useMemo)(function () {
        return {
            signInUser: signInUser,
            isRegistered: userData.isRegistered,
            user: user,
        };
    }, [signInUser, userData.isRegistered, user]);
    return (react_1.default.createElement(exports.UserContext.Provider, { value: value }, children));
};
exports.UserProvider = UserProvider;
var useUser = function () {
    var context = (0, react_1.useContext)(exports.UserContext);
    if (!context) {
        throw new Error('useUser must be used inside of a UserProvider!');
    }
    return context;
};
exports.useUser = useUser;
