"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatFileSize = void 0;
var math_utilities_1 = require("@digital-motors-boatyard/math-utilities");
var formatFileSize = function (bytes) {
    if (!bytes)
        return '0 kb';
    var kb = Math.round(bytes / 1024);
    if (kb >= 1000) {
        return (0, math_utilities_1.preciseNumber)(kb / 1024, 2, true) + ' mb';
    }
    return "".concat(kb, " kb");
};
exports.formatFileSize = formatFileSize;
