import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { ClientSource } from '@digital-motors-boatyard/common/dist/enums';
import { STATUS_SUCCESS } from '@digital-motors-boatyard/common-frontend/dist/constants';
import { removeIntlPhonePrefix } from '@digital-motors-boatyard/common-frontend/dist/utility/removeIntlPhonePrefix';
import omit from 'lodash/omit';
import {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import shortid from 'shortid';

import { createAnonymousUser } from '../../api/createAnonymousUser';
import { logoutUserAuth } from '../../api/logoutUserAuth';
import { useStorage } from '../../hooks/useStorage';
import { useAppData } from '../AppDataContext';
import {
  AuthCallback,
  AuthenticateAction,
  AuthModalView,
  LogoutAction,
  UpdateAction,
  User,
  UserContextValue,
  UserProviderProps,
  UserUpdates,
} from './types';
import { getUserFromJwt } from './utils/getUserFromJwt';
import { setAxiosAuthorizationHeader } from './utils/setAxiosAuthorizationHeader';
import { useTokenInterval } from './utils/useTokenInterval';

const BLANK_USER: User = {
  id: '',
  source: ClientSource.VTR,
  status: false,
  isAuthenticated: false as boolean,
  firstName: '',
  lastName: '',
  email: '',
  contactEmail: '',
  phoneNumber: '',
  zipcode: '',
  phoneAuthenticated: false,
};

export const UserContext = createContext<UserContextValue>({
  ...BLANK_USER,
  isRegistered: false,
  logoutUser: () => Promise.resolve(),
  updateUser: () => {},
  authenticateUser: () => Promise.resolve(),
  setAuthModalView: () => {},
  authModalView: 'hidden',
  signInPhone: '',
  setSignInPhone: () => {},
  authModalKey: shortid.generate(),
});

export const UserProvider: FC<UserProviderProps> = ({
  initialData,
  children,
}): ReactElement => {
  const storage = useStorage();
  const { tenant } = useAppData();
  const authModalKey = useRef(shortid.generate());
  const [modalView, setModalVisibility] = useState<AuthModalView>('hidden');
  const [signInPhone, setSignInPhone] = useState<string>(
    removeIntlPhonePrefix(initialData?.phoneNumber || '')
  );

  const authCallback = useRef<AuthCallback>(null);
  const { id: tenantId, configuration } = tenant;
  const { startTokenInterval, stopTokenInterval } = useTokenInterval(tenantId);

  const [user, dispatch] = useReducer(
    (
      state: User,
      action: AuthenticateAction | LogoutAction | UpdateAction
    ): User => {
      switch (action.type) {
        case 'authenticate': {
          return {
            ...state,
            ...action.data,
            isAuthenticated: true,
          };
        }
        case 'update':
          return { ...state, ...action.data };
        case 'logout':
          return BLANK_USER;
        default:
          throw new Error('invalid reducer key');
      }
    },
    { ...BLANK_USER, ...initialData }
  );

  const logoutUser: (skipDispatch?: boolean) => Promise<void> = useCallback(
    async (skipDispatch) => {
      await logoutUserAuth();
      delete axiosInstance.defaults.headers.Authorization;
      stopTokenInterval();
      storage.delete(`token__${tenantId}`);
      storage.delete(`refreshToken__${tenantId}`);
      if (!skipDispatch) dispatch({ type: 'logout' });
    },
    [storage, stopTokenInterval, tenantId]
  );

  const authenticateUser: UserUpdates['authenticateUser'] = useCallback(
    async (data, skipDispatch, callback) => {
      const jwt = storage.get(`token__${tenantId}`);
      if (!jwt) return;
      setAxiosAuthorizationHeader(jwt);
      startTokenInterval();
      if (!skipDispatch) {
        dispatch({
          type: 'authenticate',
          data: {
            ...BLANK_USER, // Ensure any old user data is cleared out
            ...omit(data, 'createdAt', 'updatedAt', 'deletedAt'),
          },
        });
      }
      if (callback) await callback();

      if (authCallback.current) {
        authCallback.current();
        authCallback.current = null;
      }
    },
    [startTokenInterval, storage, tenantId]
  );

  const logoutUserAndStartAnonymousSession: UserUpdates['logoutUser'] =
    useCallback(async () => {
      await logoutUser(true);
      // If the tenant allows anonymous users, create a new anonymous session
      if (!configuration?.web.vdp.requiresAuthentication) {
        const userRes = await createAnonymousUser(tenantId);
        if (userRes.type === STATUS_SUCCESS) {
          const { token, refreshToken } = userRes.value;
          const anonUser = getUserFromJwt(token);
          if (anonUser) {
            storage.set(`token__${tenantId}`, token);
            storage.set(`refreshToken__${tenantId}`, refreshToken, true);
            authenticateUser(anonUser);
            return;
          }
        }
      }
      // If we made it here, no anonymous user was created, so dispatch the logout
      dispatch({ type: 'logout' });
    }, [authenticateUser, configuration, logoutUser, storage, tenantId]);

  const logoutStorageEvent = useCallback(
    async (event: StorageEvent) => {
      if (event.key !== `rider__logout__${tenantId}`) return;
      window.removeEventListener('storage', logoutStorageEvent);
      await logoutUserAndStartAnonymousSession();
    },
    [logoutUserAndStartAnonymousSession, tenantId]
  );

  const value = useMemo(() => {
    window.removeEventListener('storage', logoutStorageEvent);
    if (user.isAuthenticated) {
      window.addEventListener('storage', logoutStorageEvent);
    }
    return {
      ...user,
      isRegistered: !!user.id && !!user.phoneNumber,
      logoutUser: logoutUserAndStartAnonymousSession,
      authenticateUser,
      updateUser: (data: UpdateAction['data']) => {
        dispatch({ type: 'update', data });
      },
      authModalView: modalView,
      setAuthModalView: (
        visibility: AuthModalView,
        callback?: AuthCallback
      ) => {
        if (callback) authCallback.current = callback;
        setModalVisibility(visibility);
      },
      signInPhone,
      setSignInPhone,
      authModalKey: authModalKey.current,
    };
  }, [
    authenticateUser,
    logoutStorageEvent,
    logoutUserAndStartAnonymousSession,
    modalView,
    signInPhone,
    user,
  ]);

  // When the initial data is marked as authenticated, we need
  // to set the bearer token and start the refresh interval
  if (
    initialData?.id === user.id &&
    initialData?.isAuthenticated &&
    user.isAuthenticated &&
    storage.get(`token__${tenantId}`)
  ) {
    setAxiosAuthorizationHeader(storage.get(`token__${tenantId}`));
    startTokenInterval();
  }

  return (
    <UserContext.Provider value={value as UserContextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used inside of a UserProvider!');
  }
  return context;
};
