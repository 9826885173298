import { Text } from '@digital-motors-boatyard/ui.text';
import { spacing, styled } from '@digital-motors-boatyard/ui.theme';

import { color } from '../lib/color';

const skeletonProps = `
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-size: 800px 104px;
  position: relative;
  overflow: hidden;
`;

export const SkeletonBox = styled.div<{
  boxWidth?: string;
  boxHeight?: string;
  boxRadius?: string;
}>`
  ${skeletonProps}
  background: ${color('bg05')};
  background: linear-gradient(
    to right,
    ${color('bg05')} 25%,
    ${color('bg10')} 50%,
    ${color('bg05')} 75%
  );
  border-radius: ${(props) => props.boxRadius || spacing.xxs};
  width: ${(props) => props.boxWidth || '100%'};
  height: ${(props) => props.boxHeight || '100%'};
  ${(props) => (props.boxWidth ? `min-width: ${props.boxWidth};` : '')}
  ${(props) => (props.boxHeight ? `min-height: ${props.boxHeight};` : '')}
`;

export const SkeletonText = styled(Text)<{
  boxWidth?: string;
}>`
  ${skeletonProps}
  background: ${color('bg05')};
  background: linear-gradient(
    to right,
    ${color('bg05')} 25%,
    ${color('bg10')} 50%,
    ${color('bg05')} 75%
  );
  border-radius: 2px;
  box-shadow: 0 0 0 2px ${color('background')};
  width: ${(props) => props.boxWidth || '100%'};
  ${(props) => (props.boxWidth ? `min-width: ${props.boxWidth};` : '')}
`;
SkeletonText.defaultProps = {
  children: <>&nbsp;</>,
};

export const SkeletonPill = styled(SkeletonBox)`
  border-radius: ${spacing.s};
  height: ${spacing.units(10)};
  width: ${spacing.xl};
  margin: 0 auto;
`;
