"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPartialDealSheetUpdateFromTenant = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
var pricing_1 = require("@digital-motors-boatyard/common-frontend/dist/utility/pricing");
var getPartialDealSheetUpdateFromTenant = function (_a) {
    var _b;
    var condition = _a.condition, vesselClass = _a.vesselClass, tenant = _a.tenant, dealerPrice = _a.dealerPrice;
    var requiredParams = {
        tenantId: tenant.id,
        tenantName: tenant.name,
        country: tenant.country,
        currency: tenant.currency,
    };
    if (!((_b = tenant.configuration) === null || _b === void 0 ? void 0 : _b.payments))
        return requiredParams;
    var financeType = (0, pricing_1.getDefaultPaymentType)(tenant, condition);
    return __assign(__assign({ financeType: financeType }, requiredParams), (financeType === enums_1.FinanceType.FINANCE
        ? (0, pricing_1.getLoanDefaults)({
            condition: condition,
            vesselClass: vesselClass,
            tenant: tenant,
            dealerPrice: dealerPrice,
        })
        : null));
};
exports.getPartialDealSheetUpdateFromTenant = getPartialDealSheetUpdateFromTenant;
