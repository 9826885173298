import { StylisElement, StylisPlugin } from '@emotion/cache';

// Replacement for stylis-plugin-extra-scope, which is incompatible with Stylis 4, used by Emotion 11.
export const extraScopeStylisPlugin = ({
  cacheKey,
  scope,
}: {
  cacheKey: string;
  scope: string;
}): StylisPlugin => {
  return (element: StylisElement) => {
    if (element.type !== 'rule' || element.root?.type === '@keyframes') {
      return;
    }
    const props =
      typeof element.props === 'string' ? [element.props] : element.props;

    if (props.length && props[0].startsWith(`.${cacheKey}-`)) {
      element.props = props.map((prop) => `${scope} ${prop}`);
    }
  };
};
