import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import {
  GetPricingDataRequestParams,
  PricingData,
} from '@digital-motors-boatyard/common/dist/apis';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';

import { extractData } from './extractData';

export const getPricingData = createApiRequestHandler({
  request: (params: GetPricingDataRequestParams, token?: string | null) => {
    return axiosInstance
      .post(
        `/api/core/payment/vessel-programs/v2`,
        params,
        token ? { headers: { Authorization: `Bearer ${token}` } } : undefined
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err.response);
        return err;
      });
  },
  validateData: (data: PricingData) => Boolean(data),
  extractData,
  extractMeta: () => ({}),
});
