"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreditApp = exports.CreditAppProvider = void 0;
var react_1 = __importStar(require("react"));
var useCreditAppProvider_1 = require("../../hooks/useCreditAppProvider");
var creditApplicantUtils_1 = require("../../lib/creditApplicantUtils");
var DealSheet_1 = require("../DealSheet");
var defaultCreditAppForms_1 = require("./defaultCreditAppForms");
var CreditAppContext = (0, react_1.createContext)({
    pages: [],
    hasCoApplicant: false,
    setHasCoApplicant: function () { },
});
var CreditAppProvider = function (_a) {
    var _b;
    var children = _a.children;
    var dealSheet = (0, DealSheet_1.useDealSheet)().dealSheet;
    var provider = (0, useCreditAppProvider_1.useCreditAppProvider)();
    var _c = (0, react_1.useState)(!!((_b = dealSheet.creditApplication) === null || _b === void 0 ? void 0 : _b.coApplicant)), hasCoApplicant = _c[0], setHasCoApplicant = _c[1];
    var layout = (0, react_1.useMemo)(function () { return (provider === null || provider === void 0 ? void 0 : provider.creditAppLayout) || defaultCreditAppForms_1.defaultFinanceCreditAppLayout; }, [provider]);
    var pages = (0, react_1.useMemo)(function () {
        return (0, creditApplicantUtils_1.getCreditAppPages)(layout.pages, hasCoApplicant);
    }, [layout, hasCoApplicant]);
    var contextValue = (0, react_1.useMemo)(function () {
        return {
            pages: pages,
            hasCoApplicant: hasCoApplicant,
            setHasCoApplicant: setHasCoApplicant,
        };
    }, [pages, hasCoApplicant]);
    return (react_1.default.createElement(CreditAppContext.Provider, { value: contextValue }, children));
};
exports.CreditAppProvider = CreditAppProvider;
var useCreditApp = function () { return (0, react_1.useContext)(CreditAppContext); };
exports.useCreditApp = useCreditApp;
