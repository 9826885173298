import { getPartialDealSheetUpdateFromTenant } from '@digital-motors-boatyard/by-vessel-rider.component/dist/context/DealSheet/utils/getPartialDealSheetUpdateFromTenant';
import {
  Country,
  FinanceType,
  HorizontalAlignment,
  PaymentWidgetStyle,
} from '@digital-motors-boatyard/common/dist/enums';
import { formatCurrency } from '@digital-motors-boatyard/common/dist/helpers/formatCurrency';
import { setNumeralLocale } from '@digital-motors-boatyard/common/dist/helpers/setNumeralLocale';
import { TenantConfigurationPaymentInterface } from '@digital-motors-boatyard/common/dist/interfaces';
import {
  RateTerm,
  TenantInterface,
} from '@digital-motors-boatyard/common/dist/interfaces';
import { STATUS_SUCCESS } from '@digital-motors-boatyard/common-frontend/dist/constants';
import { useEffectOnMount } from '@digital-motors-boatyard/common-frontend/dist/hooks';
import {
  calcDownPayment,
  getApplicableFees,
  getDealerFeesSum,
  getEstimatedMonthlyPayment,
  getPricingDetailsFromData,
} from '@digital-motors-boatyard/common-frontend/dist/utility/pricing';
import { Button } from '@digital-motors-boatyard/ui.button';
import {
  theme as defaultTheme,
  ThemeProvider,
} from '@digital-motors-boatyard/ui.theme';
import createCache from '@emotion/cache';
import { CacheProvider as EmotionCacheProvider } from '@emotion/react';
import isNil from 'lodash/isNil';
import numeral from 'numeral';
import { FC, useReducer, useRef } from 'react';
import ReactDOM from 'react-dom';

import { getPricingData } from '../../api/getPricingData';
import { EMOTION_CACHE_KEY, FINANCE } from '../../constants';
import {
  BoatyardDealSheetDefaults,
  useBoatyard,
} from '../../hooks/useBoatyard';
import { useStorage } from '../../hooks/useStorage';
import { extraScopeStylisPlugin } from '../../lib/extraScopeStylisPlugin';
import { FontStyles, GlobalStyles } from '../../styles';
import { Label, Wrapper } from './styles';

const WidgetDescription: FC<{
  description?: string;
  darkModeEnabled: boolean;
  useThemeStyles: boolean;
}> = ({ description, darkModeEnabled, useThemeStyles }) => {
  if (!description) {
    return null;
  }
  return useThemeStyles ? (
    <Label
      data-testid="RiderWidget__Description"
      variant="caption"
      colorKey="subtle"
      inverted={darkModeEnabled}
    >
      {description}
    </Label>
  ) : (
    <span
      data-testid="RiderWidget__Description"
      className="rider-widget-description"
    >
      {description}
    </span>
  );
};

const WidgetTitle: FC<{
  title?: string;
  darkModeEnabled: boolean;
  useThemeStyles: boolean;
}> = ({ title, darkModeEnabled, useThemeStyles }) => {
  if (!title) {
    return null;
  }
  return useThemeStyles ? (
    <Label
      data-testid="RiderWidget__Title"
      variant="heading6"
      colorKey="regular"
      inverted={darkModeEnabled}
    >
      {title}
    </Label>
  ) : (
    <span data-testid="RiderWidget__Title" className="rider-widget-title">
      {title}
    </span>
  );
};

const WidgetButton: FC<{
  buttonText: string;
  onClick: () => void;
  darkModeEnabled: boolean;
  useThemeStyles: boolean;
}> = ({ buttonText, onClick, darkModeEnabled, useThemeStyles }) => {
  return useThemeStyles ? (
    <Button
      data-testid="RiderWidget__Button"
      size="s"
      variant="accent"
      colorScope="light"
      onClick={onClick}
      inverted={darkModeEnabled}
    >
      {buttonText}
    </Button>
  ) : (
    <button
      data-testid="RiderWidget__Button"
      className="rider-widget-button"
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
};

const WidgetContent: FC<{
  title?: string;
  description?: string;
  buttonText: string;
  onClick: () => void;
  widgetStyle: PaymentWidgetStyle;
  darkModeEnabled: boolean;
  useThemeStyles: boolean;
}> = ({
  title,
  description,
  buttonText,
  onClick,
  widgetStyle,
  darkModeEnabled,
  useThemeStyles,
}) => {
  const isButtonAndTerms = widgetStyle === PaymentWidgetStyle.BUTTON_AND_TERMS;
  const isButtonAndTitle = widgetStyle === PaymentWidgetStyle.BUTTON_AND_TITLE;

  return (
    <>
      {isButtonAndTitle && (
        <>
          <WidgetTitle
            title={title}
            darkModeEnabled={darkModeEnabled}
            useThemeStyles={useThemeStyles}
          />
          <WidgetDescription
            description={description}
            darkModeEnabled={darkModeEnabled}
            useThemeStyles={useThemeStyles}
          />
        </>
      )}
      <WidgetButton
        buttonText={isButtonAndTerms && title ? title : buttonText}
        onClick={onClick}
        darkModeEnabled={darkModeEnabled}
        useThemeStyles={useThemeStyles}
      />
      {isButtonAndTerms && (
        <WidgetDescription
          description={description}
          darkModeEnabled={darkModeEnabled}
          useThemeStyles={useThemeStyles}
        />
      )}
    </>
  );
};

interface Props {
  elementId: string;
  tenant: TenantInterface;
  defaults: BoatyardDealSheetDefaults;
}

interface State {
  title: string;
  description: string;
}

export const Widget: FC<Props> = ({ elementId, tenant, defaults }) => {
  const boatyard = useBoatyard();
  const storage = useStorage();

  const [state, setState] = useReducer(
    (current: State, updates: Partial<State>) => {
      return { ...current, ...updates };
    },
    { title: '', description: '' }
  );
  const { title, description } = state;
  const {
    style = PaymentWidgetStyle.BUTTON_ONLY,
    useThemeStyles = false,
    horizontalAlignment = HorizontalAlignment.START,
    buttonText = 'Configure Payment',
    buttonRadiusPx = 20,
    fullWidthEnabled = false,
    darkModeEnabled = false,
  } = tenant.configuration?.payments?.paymentWidget || {};
  setNumeralLocale(tenant.country || Country.UNITED_STATES);
  const emotionCache = useRef(
    createCache({
      key: EMOTION_CACHE_KEY,
      stylisPlugins: [
        extraScopeStylisPlugin({
          cacheKey: EMOTION_CACHE_KEY,
          scope: `#${elementId}`,
        }),
      ],
    })
  ).current;

  useEffectOnMount(() => {
    (async () => {
      const tenantDefaults = getPartialDealSheetUpdateFromTenant({
        tenant,
        condition: defaults.condition,
        vesselClass: defaults.vesselClass,
        dealerPrice: defaults.dealerPrice,
      });
      const financeType = (defaults.financeType ||
        tenantDefaults.financeType) as FinanceType;

      // If we're displaying only the button due to style or cash payment type,
      // then there is nothing else to do
      if (style === PaymentWidgetStyle.BUTTON_ONLY || financeType !== FINANCE) {
        return;
      }

      // Fetch payment engine data
      const pricingRes = await getPricingData(
        {
          tenantPaymentConfig: tenant.configuration
            ?.payments as TenantConfigurationPaymentInterface,
          dealerId: defaults.dealerId,
          year: defaults.year,
          condition: defaults.condition,
          vesselClass: defaults.vesselClass,
        },
        storage.get(`token__${tenant.id}`)
      );
      if (pricingRes.type !== STATUS_SUCCESS) return;

      const pricing = getPricingDetailsFromData({
        pricingData: {
          ...pricingRes.value,
        },
        condition: defaults.condition,
        financeType: FINANCE,
        creditRating:
          defaults.creditRating || pricingRes.value.lender?.tiers?.[0]?.name,
        term: (defaults.term || tenantDefaults.term) as RateTerm,
      });

      // If we have pricing data, calculate the payment
      if (pricing?.term && !isNil(pricing.apr) && pricing.creditRating) {
        const dealerFees = [
          ...getApplicableFees({
            vertical: tenant.vertical,
            condition: defaults.condition,
            dealer: pricingRes.value.dealer,
          }),
          ...(defaults?.dealerFees || []),
        ];
        const dealerFeesSum = getDealerFeesSum({ dealerFees });
        const downPayment = calcDownPayment(
          (defaults.downPayment ?? tenantDefaults.downPayment) || 0,
          defaults.dealerPrice
        );
        const payment = getEstimatedMonthlyPayment({
          financeType: FINANCE,
          loan: {
            creditRating: pricing.creditRating,
            creditScoreMax: null,
            term: pricing.term,
            apr: pricing.apr,
            rateMarkup: pricing.markup || 0,
            downPayment,
          },
          basePrice: defaults.basePrice,
          dmSellingPrice: defaults.dealerPrice,
          calculatedDownPayment: downPayment,
          lenderRequirements: pricing.lenderRequirements,
          dealerFeesSum,
          discounts: [],
          fniProducts: [],
          accessories: [],
          taxRate: 0,
          titleRegistrationFeesTotal: 0,
          applicableTradeInValue: 0,
          customModelIncentives: defaults.customModelIncentives,
        });

        // If we have a payment, set the title and description
        if (!isNil(payment)) {
          setState({
            title: `Finance for ${formatCurrency(payment)} /mo*`,
            description: `*${formatCurrency(downPayment)} down • ${
              pricing.term
            } mo • ${numeral(pricing.apr).format('0[.][0]0%')} APR`,
          });
        }
      }
    })();
  });

  const container = document.getElementById(elementId);
  if (!container) return null;

  return ReactDOM.createPortal(
    useThemeStyles ? (
      <ThemeProvider theme={tenant.theme || defaultTheme}>
        <EmotionCacheProvider value={emotionCache}>
          <GlobalStyles elementId={elementId} />
          <FontStyles />
          <Wrapper
            data-testid="RiderWidget"
            horizontalAlignment={horizontalAlignment}
            buttonRadius={buttonRadiusPx}
            fullWidth={fullWidthEnabled}
          >
            <WidgetContent
              title={title}
              description={description}
              buttonText={buttonText}
              onClick={() => boatyard?.openRider(tenant.id, defaults)}
              widgetStyle={style}
              darkModeEnabled={darkModeEnabled}
              useThemeStyles={true}
            />
          </Wrapper>
        </EmotionCacheProvider>
      </ThemeProvider>
    ) : (
      <div data-testid="RiderWidget" className="rider-widget-wrapper">
        <WidgetContent
          title={title}
          description={description}
          buttonText={buttonText}
          onClick={() => boatyard?.openRider(tenant.id, defaults)}
          widgetStyle={style}
          darkModeEnabled={darkModeEnabled}
          useThemeStyles={false}
        />
      </div>
    ),
    container
  );
};
