"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditAppContent = void 0;
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
// Always keep the credit app content in the DOM to support the VGS iframes
exports.CreditAppContent = ui_theme_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: ", ";\n"], ["\n  display: ", ";\n"])), function (props) { return (props.show ? 'block' : 'none'); });
var templateObject_1;
