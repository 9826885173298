import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { Country } from '@digital-motors-boatyard/common/dist/enums';
import { UserInterface } from '@digital-motors-boatyard/common/dist/interfaces';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';
import { getIntlPhone } from '@digital-motors-boatyard/common-frontend/dist/utility/getIntlPhone';

type Payload = Pick<
  UserInterface,
  | 'firstName'
  | 'lastName'
  | 'phoneNumber'
  | 'contactEmail'
  | 'zipcode'
  | 'dealerId'
  | 'disclosures'
> & {
  id: string;
  country: Country;
};

interface Response {
  token: string | null;
}

export const updateUser = createApiRequestHandler({
  request: ({ id, country, ...userData }: Payload) => {
    return axiosInstance.patch(`/api/users/users/${id}`, {
      ...userData,
      phoneNumber: getIntlPhone(country, userData.phoneNumber),
    });
  },
  validateData: (data: Response) => data !== undefined,
});
