import { useRiderComponent } from '@digital-motors-boatyard/by-vessel-rider.component';
import { UserInterface } from '@digital-motors-boatyard/common/dist/interfaces';
import { useIsMounted } from '@digital-motors-boatyard/common-frontend/dist/hooks/useIsMounted';
import {
  exactCharacterLength,
  FormattedField,
  useForm,
} from '@digital-motors-boatyard/components.form';
import { ModalHeader } from '@digital-motors-boatyard/components.modal';
import { phoneValidationFormat } from '@digital-motors-boatyard/ui.input';
import { spacing, styled } from '@digital-motors-boatyard/ui.theme';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { saveDealSheet } from '../../api/saveDealSheet';
import { sendPhoneValidation } from '../../api/sendPhoneValidation';
import { verifySmsCode } from '../../api/verifySmsCode';
import { EventParams, useAnalytics } from '../../context/Analytics';
import { useAppData } from '../../context/AppDataContext';
import { useUser } from '../../context/User';
import { useStorage } from '../../hooks/useStorage';
import { ErrorMessage, FieldsWrapper, InfoMessage } from '../../styles';
import { PillButton } from '../PillButton';

interface Props {
  onClickBack: () => void;
  onSuccess?: () => void;
  phoneNumber: string;
  eventParams?: EventParams;
  isReEntry?: boolean;
  visible: boolean;
}

const ButtonWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${spacing.xxs};
  justify-content: center;
`;

export const PhoneAuthForm: FC<Props> = ({
  onClickBack,
  onSuccess,
  phoneNumber,
  eventParams,
  isReEntry,
  visible,
}) => {
  const storage = useStorage();
  const { trackEvent } = useAnalytics();
  const initialEventFired = useRef(false);
  const { registerSubmitHandler, registerField, getValues, validate } =
    useForm<{
      code: string;
    }>();
  const { authenticateUser } = useUser();
  const { dealSheet } = useRiderComponent();
  const { tenant } = useAppData();
  const { id: tenantId, country } = tenant;
  const [isBusy, setIsBusy] = useState(false);
  const [submissionError, setSubmissionError] = useState('');
  const isMounted = useIsMounted();

  const onResend = useCallback(async () => {
    setSubmissionError('');
    setIsBusy(true);
    const res = await sendPhoneValidation({
      phoneNumber,
      tenantId,
      country,
    });
    if (!res.success) {
      setSubmissionError(res.errorMessage || 'An error occurred');
    }
    setIsBusy(false);
  }, [country, phoneNumber, tenantId]);

  const onSubmit = registerSubmitHandler(async () => {
    const values = getValues();
    const isValid = validate(values);

    if (!values || !isValid) return;

    setSubmissionError('');
    setIsBusy(true);
    trackEvent(
      isReEntry
        ? 'rider_reenrty_verify_mobile_submit'
        : 'rider_verify_mobile_submit',
      eventParams ? eventParams : undefined
    );

    const res = await verifySmsCode({
      code: values.code,
      country,
      phoneNumber,
      tenantId,
    });

    if (res.success) {
      const user = res.user as UserInterface;

      // Update the dealsheet with the new user ID
      if (dealSheet?.id && dealSheet?.userId !== user.id) {
        await saveDealSheet({ id: dealSheet.id, userId: user.id });
      }

      // Set the tokens
      if (res.token) storage.set(`token__${tenantId}`, res.token);
      if (res.refreshToken) {
        storage.set(`refreshToken__${tenantId}`, res.refreshToken, true);
      }
      // Update the Axios header with the new token
      await authenticateUser(
        user,
        false,
        dealSheet?.id
          ? async () => {
              // Make a PATCH request again with the new JWT which causes
              // the BE dealsheet to update with the full user object
              await saveDealSheet({ id: dealSheet.id, userId: user.id });
            }
          : undefined
      );

      if (onSuccess) onSuccess();
    } else if (res.errorType === 'invalidCode') {
      setSubmissionError('Code did not match.');
    } else {
      setSubmissionError('Unable to verify code.');
    }
    if (isMounted()) setIsBusy(false);
  });

  useEffect(() => {
    if (!visible || initialEventFired.current) return;
    trackEvent(
      isReEntry ? 'rider_reenrty_verify_mobile' : 'rider_verify_mobile',
      eventParams ? eventParams : undefined
    );
    initialEventFired.current = true;
  }, [eventParams, isReEntry, trackEvent, visible]);

  return (
    <>
      <ModalHeader
        colorScope="sidebars.rider"
        title="Verify Mobile"
        leftButtonText="Back"
        leftButtonAction={onClickBack}
        noLine
      />
      <InfoMessage>
        SMS sent to number ending in &ldquo;{phoneNumber.slice(-4)}&rdquo;
      </InfoMessage>
      <FieldsWrapper>
        <FormattedField
          {...registerField('code', {
            dataType: 'numericString',
            validators: [exactCharacterLength(6, 'Must be 6 characters')],
          })}
          {...phoneValidationFormat}
          label="Enter Access Code"
          data-testid="PhoneAuthForm__Code"
          readOnly={isBusy}
        />
      </FieldsWrapper>
      {submissionError && <ErrorMessage>{submissionError}</ErrorMessage>}
      <ButtonWrapper>
        <PillButton
          data-testid="PhoneAuthForm__ResendSms"
          disabled={isBusy}
          variant="secondary"
          onClick={onResend}
        >
          Resend SMS
        </PillButton>
        <PillButton
          data-testid="PhoneAuthForm__Confirm"
          disabled={isBusy}
          variant="accent"
          onClick={onSubmit}
        >
          Confirm
        </PillButton>
      </ButtonWrapper>
    </>
  );
};
