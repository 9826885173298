"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.color = void 0;
var ui_helpers_1 = require("@digital-motors-boatyard/ui.helpers");
// Small wrapper for the UI color helper, which sets the default
// scope to "sidebars.rider" instead of "light"
var color = function (key, scope) {
    if (scope === void 0) { scope = 'sidebars.rider'; }
    return function (props) { return (0, ui_helpers_1.color)(key, scope)(props); };
};
exports.color = color;
