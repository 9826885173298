import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { DealerInterface } from '@digital-motors-boatyard/common/dist/interfaces';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';

import { DEFAULT_API_DEBOUNCE_TIME } from '../constants';

export const getDealer = createApiRequestHandler({
  request: (id: string) => {
    return axiosInstance.get(`/api/core/dealers/${id}`, {
      params: {
        expand:
          'lenders,fees,franchises,crms,inventory,tradeIn,creditAppProviderConfig',
      },
    });
  },
  validateData: (data: DealerInterface) => !!data,
  cachePrefix: 'getDealer',
  debounceTime: DEFAULT_API_DEBOUNCE_TIME,
});
