"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDealSheetVehicle = exports.DealSheetVehicleProvider = exports.DealSheetVehicleContext = void 0;
var react_1 = __importStar(require("react"));
exports.DealSheetVehicleContext = (0, react_1.createContext)(undefined);
var DealSheetVehicleProvider = function (_a) {
    var initialVehicle = _a.initialVehicle, children = _a.children;
    var vehicle = (0, react_1.useState)(initialVehicle)[0];
    var context = (0, react_1.useMemo)(function () { return ({
        vehicle: vehicle,
    }); }, [vehicle]);
    return (react_1.default.createElement(exports.DealSheetVehicleContext.Provider, { value: context }, children));
};
exports.DealSheetVehicleProvider = DealSheetVehicleProvider;
var useDealSheetVehicle = function () {
    var context = (0, react_1.useContext)(exports.DealSheetVehicleContext);
    if (!context) {
        throw new Error('useDealSheetVehicle must be used inside of a DealSheetVehicleContext!');
    }
    return context;
};
exports.useDealSheetVehicle = useDealSheetVehicle;
