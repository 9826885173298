import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { Country } from '@digital-motors-boatyard/common/dist/enums';
import { UserInterface } from '@digital-motors-boatyard/common/dist/interfaces';
import { getIntlPhone } from '@digital-motors-boatyard/common-frontend/dist/utility/getIntlPhone';
import get from 'lodash/get';

import { validateUserToken } from './validateUserToken';

export const INVALID_CODE = 'USE-WRONG_VERIFICATION_CODE';
export const INVALID_MESSAGE = 'Invalid verification code';

interface Response {
  status: number;
  data: {
    code?: 'USE-WRONG_VERIFICATION_CODE';
    data: {
      token: string;
    };
    message: string;
  };
}

interface VerificationInputs {
  code: string;
  phoneNumber: string;
  tenantId: string;
  country?: Country | null;
}

interface VerificationCodePayload {
  success: boolean;
  errorType: 'invalidCode' | 'serverError' | null;
  user: UserInterface | null;
  token: string | null;
  refreshToken: string | null;
}

export const verifySmsCode = async ({
  code,
  phoneNumber,
  tenantId,
  country,
}: VerificationInputs): Promise<VerificationCodePayload> => {
  const jwtRes: Response = await axiosInstance.post(
    '/api/users/users/auth/sms/verify',
    { code, tenantId, phoneNumber: getIntlPhone(country, phoneNumber) },
    { validateStatus: (status) => status < 500, withCredentials: true }
  );

  const token = get(jwtRes.data, 'data.token') || null;
  const refreshToken = get(jwtRes.data, 'data.refreshToken') || null;

  if (
    get(jwtRes, 'data.code') === INVALID_CODE ||
    get(jwtRes, 'data.message') === INVALID_MESSAGE
  ) {
    return {
      success: false,
      errorType: 'invalidCode',
      user: null,
      token,
      refreshToken,
    };
  }
  if (jwtRes.status === 201 && token !== null) {
    const res = await validateUserToken(token);

    if (res.success && res.user) {
      return {
        success: true,
        errorType: null,
        user: res.user,
        token,
        refreshToken,
      };
    }
  }
  return {
    success: false,
    errorType: 'serverError',
    user: null,
    token,
    refreshToken,
  };
};
