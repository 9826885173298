import { useRiderComponent } from '@digital-motors-boatyard/by-vessel-rider.component';
import { DisclosureLocation } from '@digital-motors-boatyard/common/dist/enums';
import { getTenantDisclosuresText } from '@digital-motors-boatyard/common/dist/helpers/getTenantDisclosures';
import { styled } from '@digital-motors-boatyard/ui.theme';
import { FC, useMemo } from 'react';
import { filterXSS } from 'xss';

import { useAppData } from '../context/AppDataContext';
import { color } from '../lib/color';

interface Props {
  location: DisclosureLocation;
  useDefault?: boolean;
}

const Wrapper = styled.div`
  p {
    margin: 0;
    padding: 0;
  }
  a {
    &,
    :visited,
    :active {
      color: inherit;
    }
    :hover,
    :focus {
      color: ${color('regular')};
    }
  }
`;

export const AggregatedDisclosure: FC<Props> = ({ location, useDefault }) => {
  const { tenant, dealer } = useAppData();
  const { dealSheet } = useRiderComponent();
  const text = useMemo(() => {
    const text = getTenantDisclosuresText(
      location,
      tenant,
      dealer,
      dealSheet,
      useDefault
    ).join('');
    if (!text) return '';
    return filterXSS(text);
  }, [tenant, dealer, location, dealSheet, useDefault]);

  if (!text) return null;
  return <Wrapper dangerouslySetInnerHTML={{ __html: text }} />;
};
