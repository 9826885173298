"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FETCH_PRICING_DATA_DEBOUNCE_TIME = exports.UPDATE_QUEUE_INTERVAL_MAX = exports.UPDATE_QUEUE_INTERVAL = exports.DEFAULT_PRICING_DATA = void 0;
exports.DEFAULT_PRICING_DATA = {
    lender: null,
    incentives: [],
    dealer: undefined,
    financeRates: null,
    hasPricingData: false,
    prevFetchParams: undefined,
};
exports.UPDATE_QUEUE_INTERVAL = 600;
exports.UPDATE_QUEUE_INTERVAL_MAX = 10000;
exports.FETCH_PRICING_DATA_DEBOUNCE_TIME = 600;
