"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVgsFileUploadStyles = exports.useVgsInputStyles = void 0;
var ui_helpers_1 = require("@digital-motors-boatyard/ui.helpers");
var ui_input_1 = require("@digital-motors-boatyard/ui.input");
var ui_text_1 = require("@digital-motors-boatyard/ui.text");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var react_1 = require("react");
var useIsInverted_1 = require("../hooks/useIsInverted");
var useVgsInputStyles = function () {
    var theme = (0, ui_theme_1.useTheme)();
    var colorScope = (0, useIsInverted_1.useDefaultColorScope)();
    var getColor = (0, react_1.useCallback)(function (colorKey) { return (0, ui_helpers_1.color)(colorKey, colorScope)({ theme: theme }); }, [colorScope, theme]);
    var font = theme.typography.body;
    var fontFace = (0, react_1.useMemo)(function () {
        var _a;
        var fontFamily = (_a = font.family.split(/[ ,]+/)[0]) === null || _a === void 0 ? void 0 : _a.replace(/"/g, '');
        var matchingFont = theme.fonts.find(function (fontFace) {
            return fontFace.fontFamily === fontFamily && fontFace.weight === font.weight;
        });
        if (!matchingFont)
            return null;
        var format = matchingFont.src.format === 'otf'
            ? ''
            : "format('".concat(matchingFont.src.format === 'ttf'
                ? 'truetype'
                : matchingFont.src.format, "')");
        return {
            'font-family': matchingFont.fontFamily,
            src: "url('".concat(matchingFont.src.url, "') ").concat(format).trim(),
            'font-weight': "".concat(matchingFont.weight),
            'font-style': matchingFont.style,
        };
    }, [font, theme.fonts]);
    var fieldStyles = (0, react_1.useMemo)(function () { return (__assign({ 'box-sizing': 'border-box', display: 'block', height: ui_input_1.INPUT_HEIGHT, width: '100%', background: getColor('bg05'), color: getColor('regular'), 'font-family': font.family, 'font-size': font.size, 'font-weight': "".concat(font.weight), 'letter-spacing': font.spacing || 'normal', 'text-transform': font.transform, 'line-height': "".concat(ui_text_1.lineHeights.body), outline: '0', 'margin-bottom': '0', border: '2px solid transparent', 'border-radius': '10px', padding: '19px 20px 0', '&:focus': {
            outline: 0,
            // TODO This won't go beyond the content of the iFrame, maybe fixable?
            //'box-shadow': `0 0 0 4px ${rgba(getColor('regular'), 0.26)}`,
            'border-color': getColor('regular'),
        }, '&.invalid.dirty': {
            'border-color': (0, ui_helpers_1.rgba)(getColor('danger'), 0.26),
        } }, (fontFace
        ? {
            '@font-face': fontFace,
        }
        : undefined))); }, [getColor, font, fontFace]);
    return fieldStyles;
};
exports.useVgsInputStyles = useVgsInputStyles;
var useVgsFileUploadStyles = function () {
    var theme = (0, ui_theme_1.useTheme)();
    var colorScope = (0, useIsInverted_1.useDefaultColorScope)();
    var getColor = (0, react_1.useCallback)(function (colorKey) { return (0, ui_helpers_1.color)(colorKey, colorScope)({ theme: theme }); }, [colorScope, theme]);
    var fieldStyles = (0, react_1.useMemo)(function () { return ({
        'border-radius': '25px',
        background: getColor('accent'),
        '&:hover': {
            background: (0, ui_helpers_1.rgba)(getColor('accent'), 0.9),
        },
        color: getColor('background'),
        border: 'none',
        outline: '0',
        margin: '0',
        cursor: 'pointer',
        input: {
            display: 'none',
        },
    }); }, [getColor]);
    return fieldStyles;
};
exports.useVgsFileUploadStyles = useVgsFileUploadStyles;
