import { ProviderLogo } from '@digital-motors-boatyard/common/dist/enums';
import { Text } from '@digital-motors-boatyard/ui.text';
import { spacing, styled } from '@digital-motors-boatyard/ui.theme';
import { FC } from 'react';

import { TENANT_PROVIDER_DATA } from '../constants';
import { useAppData } from '../context/AppDataContext';
import { useIsInverted } from '../hooks/useIsInverted';
import { color } from '../lib/color';

const Wrapper = styled(Text)`
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: center;
  color: ${color('disabled')};
`;
Wrapper.defaultProps = {
  variant: 'caption',
};

const Image = styled.img<{ inverted?: boolean }>`
  ${(props) => {
    if (!props.inverted) return '';
    return 'filter: invert(1);';
  }}
  height: ${spacing.units(6)};
  width: auto;
  margin: 0 ${spacing.xxs};
`;

export const PoweredBy: FC = () => {
  const isInverted = useIsInverted();
  const {
    tenant: { configuration },
  } = useAppData();
  const { url, alt } = configuration?.providerLogo
    ? TENANT_PROVIDER_DATA[configuration.providerLogo].logo
    : TENANT_PROVIDER_DATA[ProviderLogo.NONE].logo;

  if (!url) return null;
  return (
    <Wrapper>
      Powered by{' '}
      <Image
        data-testid="PoweredBy__Logo"
        src={url}
        alt={alt}
        inverted={isInverted}
      />
    </Wrapper>
  );
};
