import { useRef } from 'react';

export const useBeforeMount = (callback: () => void) => {
  const beforeMount = useRef(true);

  if (beforeMount.current) {
    beforeMount.current = false;
    callback();
  }
};
