import { Text, TextColorKey } from '@digital-motors-boatyard/ui.text';
import { spacing, styled } from '@digital-motors-boatyard/ui.theme';

import { color } from '../../lib/color';

export const Wrapper = styled.div<{
  horizontalAlignment: string;
  buttonRadius: number;
  fullWidth: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => props.horizontalAlignment};
  user-select: none;

  button {
    border-radius: ${(props) => props.buttonRadius}px !important;
    ${(props) => (props.fullWidth ? 'width: 100%;' : '')}
  }

  span + button,
  button + span {
    margin-top: ${spacing.xxs};
  }
`;

export const Label = styled(Text)<{
  inverted: boolean;
  colorKey: TextColorKey;
}>`
  color: ${(props) =>
    color(props.colorKey, props.inverted ? 'dark' : 'light')(props)};
`;
