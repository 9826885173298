import {
  DealerInterface,
  TenantInterface,
} from '@digital-motors-boatyard/common/dist/interfaces';
import {
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
} from 'react';

interface AppDataContextData {
  tenant: TenantInterface;
  dealer: DealerInterface;
}

interface AppDataProviderProps {
  tenant: TenantInterface;
  dealer: DealerInterface;
  children: ReactNode;
}

const AppDataContext = createContext<AppDataContextData>({
  tenant: {} as TenantInterface,
  dealer: {} as DealerInterface,
});

export const AppDataProvider: FC<AppDataProviderProps> = ({
  tenant,
  dealer,
  children,
}): ReactElement => {
  const context = useMemo(
    () => ({
      tenant,
      dealer,
    }),
    [tenant, dealer]
  );

  return (
    <AppDataContext.Provider value={context}>
      {children}
    </AppDataContext.Provider>
  );
};

export const useAppData = () => {
  const context = useContext(AppDataContext);

  if (!context) {
    throw new Error('useAppData must be used inside of a AppDataProvider!');
  }
  return context;
};
