import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { DealSheetVehicle } from '@digital-motors-boatyard/common/dist/interfaces';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';

import { DEFAULT_API_DEBOUNCE_TIME } from '../constants';
import { extractData } from './extractData';

export const updateVehicle = createApiRequestHandler({
  request: (dealSheetId: string, vehicle: Partial<DealSheetVehicle>) => {
    if (!dealSheetId) {
      throw Error('Must provide deal sheet id.');
    }
    return axiosInstance.patch(
      `/api/core/dealsheets/${dealSheetId}/vehicle`,
      vehicle
    );
  },
  extractData,
  validateData: (data: Response) => !!data,
  debounceTime: DEFAULT_API_DEBOUNCE_TIME,
});
