import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { ClientSource } from '@digital-motors-boatyard/common/dist/enums';
import { DealSheet } from '@digital-motors-boatyard/common/dist/interfaces';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';
import omit from 'lodash/omit';

import { extractData } from './extractData';

export const createDealSheet = createApiRequestHandler({
  request: (dealSheet: Partial<DealSheet>) => {
    return axiosInstance.post(`/api/core/dealsheets`, {
      ...omit(dealSheet, 'id', 'createdAt', 'updatedAt'),
      source: ClientSource.VTR,
    });
  },
  extractData: (res) => {
    const deal = extractData<DealSheet>(res);
    // tenantUserId is used for sales portal desking only and should be
    // removed from being used in the store
    delete deal.tenantUserId;
    return deal;
  },
  validateData: (data: DealSheet) => !!data,
});
