import { useRiderComponent } from '@digital-motors-boatyard/by-vessel-rider.component';
import {
  CanadianProvince,
  Country,
  UnitedStatesState,
} from '@digital-motors-boatyard/common/dist/enums';
import { keyDownPostalCodeForCountry } from '@digital-motors-boatyard/common-frontend/dist/utility/formatPostalCode';
import {
  exactCharacterLength,
  inNumericRange,
  matchesRegex,
  required,
  ValidateField,
} from '@digital-motors-boatyard/components.form';
import { Props as InputProps } from '@digital-motors-boatyard/ui.input';
import { css, SerializedStyles } from '@emotion/react';
import { KeyboardEvent, useMemo } from 'react';

// We currently only support US/CA trade-in configuration. If we
// add new countries in the future, this configuration will need
// to be updated as well

interface CountryProperties {
  zipLabel: string;
  zipLength: number;
  zipValidation: {
    required: ValidateField;
    charLength: ValidateField;
    pattern: ValidateField;
  };
  zipInputProps: Partial<Omit<InputProps, 'ref'>> & { css?: SerializedStyles };
  distanceLabel: string;
  odometerLabel: string;
  odometerValidation: {
    required: ValidateField;
    inNumericRange: ValidateField;
  };
  stateLabel: string;
  stateEnum: typeof UnitedStatesState | typeof CanadianProvince;
  ssnLabel: string;
  ssnLabelAbbv: string;
}

interface CountryPropertiesMap {
  [Country.UNITED_STATES]: CountryProperties;
  [Country.CANADA]: CountryProperties;
}

const keyDownHandler =
  (country: Country) => (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key.length > 1) return e;

    const { value, selectionStart: start, selectionEnd: end } = e.currentTarget;

    if (value && start !== null && end !== null && start !== end) {
      e.currentTarget.value = value.replace(value.substring(start, end), '');
      e.currentTarget.selectionStart = start;
      e.currentTarget.selectionEnd = start;
    }
    keyDownPostalCodeForCountry(country)(e);
  };

export const COUNTRY_PROPERTIES: CountryPropertiesMap = {
  [Country.UNITED_STATES]: {
    zipLabel: 'ZIP Code',
    zipLength: 5,
    zipValidation: {
      required,
      charLength: exactCharacterLength(5, 'Zip code must be 5 digits'),
      pattern: matchesRegex(/\d+/g, 'Zip code is not valid'),
    },
    zipInputProps: {
      maxLength: 5,
      onKeyDown: keyDownHandler(Country.UNITED_STATES),
      inputMode: 'numeric',
    },
    distanceLabel: 'mi',
    odometerLabel: 'Current Mileage (Odometer)',
    odometerValidation: {
      required,
      inNumericRange: inNumericRange(
        0,
        499999,
        'Must be between 0 and 499,999 miles'
      ),
    },
    stateLabel: 'State',
    stateEnum: UnitedStatesState,
    ssnLabel: 'Social Security Number',
    ssnLabelAbbv: 'SSN',
  },
  [Country.CANADA]: {
    zipLabel: 'Postal Code',
    zipLength: 7,
    zipValidation: {
      required,
      // length is 7 instead of 6 to allow a formatted space
      charLength: exactCharacterLength(7, 'Postal code must be 6 characters'),
      pattern: matchesRegex(
        /[a-z]\d[a-z]\s?\d[a-z]\d/gi,
        'Postal code is not valid'
      ),
    },
    zipInputProps: {
      maxLength: 7,
      onKeyDown: keyDownHandler(Country.CANADA),
      css: css`
        input {
          text-transform: uppercase;
        }
      `,
    },
    distanceLabel: 'km',
    odometerLabel: 'Current Kilometers (Odometer)',
    odometerValidation: {
      required,
      inNumericRange: inNumericRange(
        0,
        499999,
        'Must be between 0 and 499,999 kilometers'
      ),
    },
    stateLabel: 'Province',
    stateEnum: CanadianProvince,
    ssnLabel: 'Social Insurance Number',
    ssnLabelAbbv: 'SIN',
  },
};

export const useCountryProperties = () => {
  const { dealSheet } = useRiderComponent();
  const { country } = dealSheet || {};

  return useMemo(() => {
    return COUNTRY_PROPERTIES[
      country === Country.CANADA ? country : Country.UNITED_STATES
    ];
  }, [country]);
};
