import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { UserInterface } from '@digital-motors-boatyard/common/dist/interfaces';
import get from 'lodash/get';

interface ValidationMessage {
  success: boolean;
  user: UserInterface | null;
}

const INVALID_TOKEN_MESSAGE = 'USE-INVALID_TOKEN';

export const validateUserToken = async (
  token: string
): Promise<ValidationMessage> => {
  try {
    const response = await axiosInstance.get('/api/users/users/validate', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      // If the token is invalid the API will respond w/ a 200 status
      // but the `data` key will contain a message saying that the token is invalid
      const hasInvalidToken =
        get(response, 'data.data.code') === INVALID_TOKEN_MESSAGE;
      const user = get(response, 'data.data');

      if (hasInvalidToken || !user) {
        return { success: false, user: null };
      }
      return { success: true, user };
    }
    // We want to swallow this to prevent a 500 error page
    // eslint-disable-next-line no-empty
  } catch {}
  return { success: false, user: null };
};
