import { useTheme } from '@digital-motors-boatyard/ui.theme';
import { FC } from 'react';

import { color } from '../lib/color';

interface Props {
  elementId?: string;
}

export const GlobalStyles: FC<Props> = ({ elementId = 'boatyard-rider' }) => {
  const theme = useTheme();

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
          #${elementId} {
            color: ${color('regular')({ theme })};
            font-size: 16px !important;
            z-index: auto !important;
          }
          #${elementId} * {
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            box-sizing: border-box;
            color: inherit;
          }
          #${elementId} h1,
          #${elementId} h2,
          #${elementId} h3,
          #${elementId} h4,
          #${elementId} h5,
          #${elementId} h6,
          #${elementId} p {
            margin: 0;
            padding: 0;
          }
        `,
      }}
    />
  );
};
