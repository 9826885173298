"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.businessPages = exports.individualPages = void 0;
var interfaces_1 = require("@digital-motors-boatyard/common/dist/interfaces");
var sharedAddressPage = {
    name: 'Address',
    forCoApplicant: false,
    sections: [
        {
            title: '',
            type: interfaces_1.CreditAppSectionType.OTHER,
            fields: [
                interfaces_1.CreditAppField.RESIDENCE_ADDRESS_LINE1,
                interfaces_1.CreditAppField.RESIDENCE_CITY,
                interfaces_1.CreditAppField.RESIDENCE_REGION,
                interfaces_1.CreditAppField.RESIDENCE_POSTAL_CODE,
            ],
        },
    ],
};
var sharedDisclosurePage = {
    name: 'Disclosures',
    forCoApplicant: false,
    sections: [
        {
            title: '',
            type: interfaces_1.CreditAppSectionType.OTHER,
            fields: [],
        },
    ],
};
exports.individualPages = [
    {
        name: 'Customer',
        forCoApplicant: false,
        sections: [
            {
                title: '',
                type: interfaces_1.CreditAppSectionType.PERSONALINFO,
                fields: [
                    interfaces_1.CreditAppField.APPLICANT_FIRST_NAME,
                    interfaces_1.CreditAppField.APPLICANT_MIDDLE_NAME,
                    interfaces_1.CreditAppField.APPLICANT_LAST_NAME,
                    interfaces_1.CreditAppField.APPLICANT_GENERATION,
                    interfaces_1.CreditAppField.APPLICANT_BIRTH_DATE,
                    interfaces_1.CreditAppField.APPLICANT_SSN,
                    interfaces_1.CreditAppField.APPLICANT_MOBILE_PHONE,
                    interfaces_1.CreditAppField.APPLICANT_EMAIL,
                    interfaces_1.CreditAppField.BUSINESS_YEAR_EST,
                    interfaces_1.CreditAppField.BUSINESS_PHONE,
                    interfaces_1.CreditAppField.APPLICANT_OCCUPATION,
                ],
            },
        ],
    },
    sharedAddressPage,
    sharedDisclosurePage,
];
exports.businessPages = [
    {
        name: 'Business',
        forCoApplicant: false,
        sections: [
            {
                title: '',
                type: interfaces_1.CreditAppSectionType.BUSINESS_INFO,
                fields: [
                    interfaces_1.CreditAppField.BUSINESS_TYPE,
                    interfaces_1.CreditAppField.BUSINESS_NAME,
                    interfaces_1.CreditAppField.BUSINESS_TAX_ID,
                    interfaces_1.CreditAppField.BUSINESS_EMAIL,
                    interfaces_1.CreditAppField.BUSINESS_YEAR_EST,
                    interfaces_1.CreditAppField.BUSINESS_PHONE,
                    interfaces_1.CreditAppField.APPLICANT_OCCUPATION,
                ],
            },
        ],
    },
    sharedAddressPage,
    sharedDisclosurePage,
];
