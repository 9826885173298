import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { TenantInterface } from '@digital-motors-boatyard/common/dist/interfaces';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';

import { DEFAULT_API_DEBOUNCE_TIME } from '../constants';

export const getTenant = createApiRequestHandler({
  request: (id: string) => {
    return axiosInstance.get(`/api/core/tenants/${id}`, {
      params: {
        expand:
          'configuration,theme,dealers,disclosures,creditAppProviderConfig,crms',
      },
    });
  },
  validateData: (data: TenantInterface) => !!data,
  cachePrefix: 'getTenant',
  debounceTime: DEFAULT_API_DEBOUNCE_TIME,
});
