import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { DealSheet } from '@digital-motors-boatyard/common/dist/interfaces';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';
import omit from 'lodash/omit';

import { DEFAULT_API_DEBOUNCE_TIME } from '../constants';
import { extractData } from './extractData';

export const saveDealSheet = createApiRequestHandler({
  request: (dealSheet: Partial<DealSheet>) => {
    return axiosInstance.patch(
      `/api/core/dealsheets/${dealSheet.id}`,
      omit(dealSheet, 'id', 'createdAt', 'updatedAt')
    );
  },
  extractData,
  validateData: (data: DealSheet) => !!data,
  debounceTime: DEFAULT_API_DEBOUNCE_TIME,
});
