import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import {
  ClientSource,
  Country,
} from '@digital-motors-boatyard/common/dist/enums';
import { UserInterface } from '@digital-motors-boatyard/common/dist/interfaces';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';
import { getIntlPhone } from '@digital-motors-boatyard/common-frontend/dist/utility/getIntlPhone';

type Data = Pick<
  UserInterface,
  | 'firstName'
  | 'lastName'
  | 'phoneNumber'
  | 'contactEmail'
  | 'zipcode'
  | 'disclosures'
> &
  Partial<UserInterface> & {
    country: Country | null;
  };

interface Response {
  token: string | null;
  refreshToken: string | null;
}

export const createUser = createApiRequestHandler({
  request: ({ country, ...userData }: Data) => {
    return axiosInstance.post('/api/users/users', {
      ...userData,
      source: ClientSource.VTR,
      phoneNumber: getIntlPhone(country, userData.phoneNumber),
    });
  },
  validateData: (data: Response) => data !== undefined,
});
