"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomizePayment = void 0;
var components_form_1 = require("@digital-motors-boatyard/components.form");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var react_1 = __importStar(require("react"));
var constants_1 = require("../../constants");
var Pricing_1 = require("../../context/Pricing");
var DealSheetPricingContext_1 = require("../../context/Pricing/DealSheetPricingContext");
var useIsInverted_1 = require("../../hooks/useIsInverted");
var DownPaymentSlider_1 = require("./DownPaymentSlider");
var TermSelector_1 = require("./TermSelector");
var Container = ui_theme_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-row-gap: ", ";\n  margin: 0 0 ", ";\n"], ["\n  display: grid;\n  grid-row-gap: ", ";\n  margin: 0 0 ", ";\n"])), ui_theme_1.spacing.xs, ui_theme_1.spacing.xs);
var CustomizePayment = function () {
    var _a;
    var isInverted = (0, useIsInverted_1.useIsInverted)();
    var _b = (0, DealSheetPricingContext_1.useDealSheetPricing)(), pricing = _b.pricing, updateDealSheetPricing = _b.updateDealSheetPricing;
    var _c = pricing || {}, contextCreditRating = _c.creditRating, creditScoreMax = _c.creditScoreMax, financeType = _c.financeType, term = _c.term;
    var _d = (0, Pricing_1.usePricingContext)(), hasPricingData = _d.hasPricingData, dealSheetPricing = _d.dealSheetPricing, dealSheetCalculations = _d.dealSheetCalculations, defaultCreditRating = _d.defaultCreditRating, getBestMatchingPricingParams = _d.getBestMatchingPricingParams, hasRatesForCreditRating = _d.hasRatesForCreditRating;
    var tiers = ((_a = dealSheetPricing === null || dealSheetPricing === void 0 ? void 0 : dealSheetPricing.lender) === null || _a === void 0 ? void 0 : _a.tiers) || [];
    var maxDownPayment = dealSheetCalculations.maxDownPayment;
    var creditRating = contextCreditRating || defaultCreditRating;
    // Ensure that only an available credit+term combination is used
    (0, react_1.useEffect)(function () {
        if (!hasPricingData || !updateDealSheetPricing)
            return;
        if (financeType === constants_1.FINANCE) {
            var params = getBestMatchingPricingParams({
                creditRating: creditRating,
                creditScoreMax: creditScoreMax,
                term: term,
            });
            if (params.creditRating && params.term) {
                if (params.creditRating !== creditRating ||
                    params.creditScoreMax !== creditScoreMax ||
                    params.term !== term) {
                    updateDealSheetPricing(params);
                }
            }
            else {
                updateDealSheetPricing({ financeType: constants_1.CASH });
            }
        }
    }, [
        creditRating,
        creditScoreMax,
        financeType,
        getBestMatchingPricingParams,
        hasPricingData,
        term,
        updateDealSheetPricing,
    ]);
    if (!tiers.length || !creditRating) {
        return null;
    }
    return (react_1.default.createElement(Container, null,
        !!maxDownPayment && react_1.default.createElement(DownPaymentSlider_1.DownPaymentSlider, null),
        react_1.default.createElement(TermSelector_1.TermSelector, null),
        tiers.length > 1 && (react_1.default.createElement(components_form_1.SelectField, { key: creditRating, label: "Credit Rating", "data-testid": "CustomizePayment__CreditRating", defaultValue: creditRating, onChange: function (e) {
                var _a;
                var creditScoreMax = (_a = tiers.find(function (tier) { return tier.name === e.target.value; })) === null || _a === void 0 ? void 0 : _a.ficoHigh;
                updateDealSheetPricing &&
                    updateDealSheetPricing({
                        creditRating: e.target.value,
                        creditScoreMax: creditScoreMax,
                    });
            }, inverted: isInverted }, tiers.map(function (tier) { return (react_1.default.createElement("option", { key: tier.name, value: tier.name, disabled: !hasRatesForCreditRating(tier.name) }, "".concat(tier.label, " (").concat(tier.ficoLow, "-").concat(tier.ficoHigh, ")"))); })))));
};
exports.CustomizePayment = CustomizePayment;
var templateObject_1;
