import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { DealSheet } from '@digital-motors-boatyard/common/dist/interfaces';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';
import { AxiosResponseTransformer } from 'axios';

import { extractData } from './extractData';

export const getDealSheet = createApiRequestHandler({
  request: (id: string) => {
    return axiosInstance.get(`/api/core/dealsheets/${id}`, {
      params: {
        expand: [
          'tradeInEstimate',
          'discounts',
          'fniProducts',
          'creditApplication',
          'lenderOffers',
          'deliveryAddress',
          'titleFees',
          'registrationFees',
          'documents',
          'accessories',
          'reservation',
        ].join(','),
      },
      transformResponse: [
        ...(axiosInstance.defaults
          .transformResponse as AxiosResponseTransformer[]),
        (dealSheet) => ({
          ...dealSheet,
          // coserce null values to actual booleans (patch does not allow null)
          showRegistrationFees: !!dealSheet.showRegistrationFees,
          showTitleFees: !!dealSheet.showTitleFees,
          // tenantUserId is used for sales portal desking only and should be
          // removed from being used in the store
          tenantUserId: undefined,
        }),
      ],
    });
  },
  validateData: (data: DealSheet) => Boolean(data),
  extractData,
});
