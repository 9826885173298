import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import { ClientSource } from '@digital-motors-boatyard/common/dist/enums';
import { createApiRequestHandler } from '@digital-motors-boatyard/common-frontend/dist/utility/createApiRequestHandler';

interface AnonymousTokenData {
  token: string;
  refreshToken: string;
}

export const createAnonymousUser = createApiRequestHandler({
  request: (tenantId: string) => {
    return axiosInstance.post('/api/users/users/auth/anonymous', {
      tenantId,
      source: ClientSource.VTR,
    });
  },
  validateData: (data: AnonymousTokenData) => data !== undefined,
});
