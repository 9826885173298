import { rgba } from '@digital-motors-boatyard/ui.helpers';
import { Props as TextProps, Text } from '@digital-motors-boatyard/ui.text';
import {
  borderRadius,
  spacing,
  styled,
} from '@digital-motors-boatyard/ui.theme';

import { RIDER_WIDTH } from '../constants';
import { color } from '../lib/color';

export const SiteOverlay = styled.div<{ isOpen: boolean }>`
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  background: ${(props) => rgba(color('background', 'dark')(props), 0.26)};
  position: fixed;
  top: 0;
  right: ${(props) => (props.isOpen ? 0 : '100%')};
  bottom: ${(props) => (props.isOpen ? 0 : '100%')};
  left: 0;
  z-index: 2147483666;
  user-select: none;
  pointer-events: ${(props) => (props.isOpen ? 'initial' : 'none')};
  transition: opacity 400ms ease-in-out;

  @media screen and (min-width: 600px) {
    backdrop-filter: blur(24px);
  }
`;

export const VesselRiderContainer = styled.aside<{ isOpen: boolean }>`
  background: ${color('background')};
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  ${(props) => (props.isOpen ? '' : `transform: scale(0.9);`)}
  transition: transform 250ms ease-in-out;

  @media screen and (min-width: 600px) {
    width: ${RIDER_WIDTH}px;
    ${(props) =>
      props.isOpen ? '' : `transform: translateX(${RIDER_WIDTH}px);`}
  }
`;

export const VesselRiderContent = styled.div`
  flex-grow: 1;
  padding: ${spacing.xs};
`;

export const ExpandableGrid = styled.div`
  display: grid;
  grid-row-gap: ${spacing.xxs};
`;

export const ErrorMessage = styled(Text)`
  display: block;
  align-self: stretch;
  margin: ${spacing.xxs} 0 0;
  padding: ${spacing.xxs};
  color: ${color('background')};
  background: ${color('danger')};
  border-radius: ${borderRadius.xs};

  + div,
  + button {
    margin-top: ${spacing.xxs};
  }
`;
ErrorMessage.defaultProps = {
  bold: true,
  as: 'mark',
  variant: 'small',
};

export const InfoMessage = styled(Text)<TextProps & { margin?: string }>`
  border: 1px solid ${color('disabled')};
  border-radius: ${borderRadius.s};
  margin: ${(props) => props.margin || `0 0 ${spacing.xxs}`};
  padding: ${spacing.xxs};
  text-align: center;
`;
InfoMessage.defaultProps = {
  as: 'p',
  variant: 'small',
};

export const FieldsWrapper = styled.div<{ margin?: string }>`
  display: grid;
  grid-row-gap: ${spacing.xxs};
  padding: ${spacing.xs} 0;
  margin: ${(props) => props.margin || `0 0 ${spacing.xs}`};
`;

export const FieldDisclaimer = styled.div`
  span {
    padding: 0 ${spacing.xs};
    text-align: left;
  }
`;

export const Disclaimer = styled(Text)`
  background: ${color('divider')};
  color: ${color('subtle')};
  padding: ${spacing.xs} !important;
  margin: ${spacing.xs} 0 0 !important;
`;
Disclaimer.defaultProps = {
  as: 'div',
  variant: 'caption',
};
