import {
  ConsentDisclosure,
  useRiderComponent,
} from '@digital-motors-boatyard/by-vessel-rider.component';
import {
  Country,
  DisclosureLocation,
} from '@digital-motors-boatyard/common/dist/enums';
import {
  TenantDisclosureInterface,
  UserInterface,
} from '@digital-motors-boatyard/common/dist/interfaces';
import SignUpDisclosure from '@digital-motors-boatyard/common/dist/interfaces/entity/user/SignUpDisclosure.interface';
import { STATUS_SUCCESS } from '@digital-motors-boatyard/common-frontend/dist/constants';
import {
  exactCharacterLength,
  FormattedField,
  isEmail,
  isValidPhoneForCountry,
  required,
  TextField,
  useForm,
} from '@digital-motors-boatyard/components.form';
import { ModalHeader } from '@digital-motors-boatyard/components.modal';
import { Checkbox } from '@digital-motors-boatyard/ui.checkbox';
import { phoneFormat } from '@digital-motors-boatyard/ui.input';
import { Text } from '@digital-motors-boatyard/ui.text';
import { spacing } from '@digital-motors-boatyard/ui.theme';
import styled from '@emotion/styled';
import get from 'lodash/get';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { createUser } from '../../api/createUser';
import { saveDealSheet } from '../../api/saveDealSheet';
import { sendPhoneValidation } from '../../api/sendPhoneValidation';
import { updateUser } from '../../api/updateUser';
import { PHONE_HELPER_TEXT } from '../../constants';
import { useAnalytics } from '../../context/Analytics';
import { useBoatyardAnalyticsParams } from '../../context/Analytics/utils/getParamsFromBoatyard';
import { useAppData } from '../../context/AppDataContext';
import { useUser } from '../../context/User';
import { getUserFromJwt } from '../../context/User/utils/getUserFromJwt';
import { useBoatyard } from '../../hooks/useBoatyard';
import { useCountryProperties } from '../../hooks/useCountryProperties';
import { useStorage } from '../../hooks/useStorage';
import { color } from '../../lib/color';
import { ErrorMessage, FieldDisclaimer, FieldsWrapper } from '../../styles';
import { AggregatedDisclosure } from '../AggregatedDisclosure';
import { PillButton, PillButtonGroup } from '../PillButton';
import { Disclosure } from './Disclosure';

interface Props {
  visible: boolean;
  dismiss?: () => void;
}

const Disclaimer = styled(Text)`
  color: ${color('subtle')};
  display: block;
  margin: 0;
  padding: ${spacing.xs} ${spacing.xs} 0;
`;
Disclaimer.defaultProps = {
  as: 'div',
  variant: 'caption',
};

const getSortedDisclosures = (
  location: DisclosureLocation,
  disclosures?: TenantDisclosureInterface[]
) => {
  return (
    disclosures
      ?.filter((d) => d.location.includes(location))
      .sort((a, b) => a.priority - b.priority) || []
  );
};

export const RegistrationForm: FC<Props> = ({ visible, dismiss }) => {
  const boatyard = useBoatyard();
  const storage = useStorage();
  const { dealSheet } = useRiderComponent();
  const { trackEvent } = useAnalytics();
  const analyticsParams = useBoatyardAnalyticsParams();
  const initialEventFired = useRef(false);
  const {
    setAuthModalView,
    signInPhone: defaultPhone,
    setSignInPhone,
    ...user
  } = useUser();
  const { registerSubmitHandler, registerField, getValues, validate } =
    useForm<UserInterface>();
  const { tenant, dealer } = useAppData();
  const { id: tenantId, country, configuration, disclosures } = tenant;
  const { id: dealerId } = dealer;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');
  const { zipLabel, zipInputProps, zipValidation } = useCountryProperties();

  const [consented, setConsented] = useState(0);
  const updateConsent = useCallback(
    (target: HTMLInputElement) => {
      target.checked
        ? setConsented(consented + 1)
        : setConsented(consented - 1);
    },
    [consented]
  );

  const consentDisclosures = getSortedDisclosures(
    DisclosureLocation.CONSENT_REGISTRATION,
    disclosures
  );

  const optionalDisclosures = getSortedDisclosures(
    DisclosureLocation.OPTIONAL_REGISTRATION,
    disclosures
  );

  const onSubmit = registerSubmitHandler(async () => {
    if (consented !== consentDisclosures.length) {
      setSubmissionError(
        'Please read and check the required checkboxes to proceed.'
      );
      return;
    }

    const values = getValues();
    const isValid = validate(values);

    if (!values || !isValid) return;

    const signUpDisclosures: SignUpDisclosure[] = [];
    optionalDisclosures.forEach((d, i) => {
      signUpDisclosures.push({
        name: d.name,
        value: values.disclosures?.[i]?.value ?? false,
      });
    });

    setSubmissionError('');
    setIsSubmitting(true);

    const payload = {
      ...values,
      dealerId,
      country: country as Country,
      disclosures: signUpDisclosures,
    };
    const res = await (user.isAuthenticated
      ? updateUser({ ...payload, id: user.id })
      : createUser({ ...payload, tenantId }));
    const serverMessage = get(res, 'error.response.data.message') || '';

    setSignInPhone(values.phoneNumber);
    trackEvent('rider_signup_submit', {
      user_zip: values.zipcode,
      ...analyticsParams,
    });

    // If the tenant does not require phone verification to sign up,
    // authenticate them immediately
    if (
      res.type === STATUS_SUCCESS &&
      !configuration?.web.global.requiresPhoneAuthentication &&
      !serverMessage.match(/already\s+exists/i)
    ) {
      const { token } = res.value;
      const refreshToken = get(res, 'value.refreshToken');
      const userData = token && getUserFromJwt(token);

      if (token && userData && userData.id) {
        storage.set(`token__${tenantId}`, token);
        if (refreshToken) {
          storage.set(`refreshToken__${tenantId}`, refreshToken, true);
        }
        await user.authenticateUser(
          userData,
          false,
          dealSheet?.id
            ? async () => {
                // Make a PATCH request again with the new JWT which causes
                // the BE dealsheet to update with the full user object
                await saveDealSheet({ id: dealSheet.id, userId: user.id });
              }
            : undefined
        );
      }
      setIsSubmitting(false);
      setAuthModalView('hidden');
      return;
    }

    // Send a phone verificaion code if the registration was successful, or
    // if the user already exists (instead of redirecting to the login flow)
    if (
      res.type === STATUS_SUCCESS ||
      serverMessage.match(/already\s+exists/i)
    ) {
      sendPhoneValidation({
        phoneNumber: values.phoneNumber,
        tenantId,
        country,
      });
      setAuthModalView('verify');
    } else {
      // Otherwise display a generic error
      setSubmissionError('An error occurred. Please try again.');
    }
    setIsSubmitting(false);
  });

  useEffect(() => {
    if (!visible || initialEventFired.current) return;
    trackEvent(
      boatyard?.vessel?.user ? 'rider_signup_prepopulated' : 'rider_signup',
      { user_zip: boatyard?.vessel?.user?.zipcode || null, ...analyticsParams }
    );
    initialEventFired.current = true;
  }, [analyticsParams, boatyard, trackEvent, visible]);

  return (
    <>
      <ModalHeader
        colorScope="sidebars.rider"
        title="Welcome"
        leftButtonText="Cancel"
        leftButtonAction={dismiss || boatyard?.closeRider}
        noLine
      />
      <PillButtonGroup>
        <PillButton data-testid="RegistrationForm__SignUp" active>
          Sign Up
        </PillButton>
        <PillButton
          data-testid="RegistrationForm__SignIn"
          onClick={() => setAuthModalView('signin')}
        >
          Sign In
        </PillButton>
      </PillButtonGroup>
      <FieldsWrapper>
        <TextField
          {...registerField('firstName', { validators: [required] })}
          label="First Name"
          data-testid="RegistrationForm__FirstName"
          readOnly={isSubmitting}
          defaultValue={user.firstName || undefined}
        />
        <TextField
          {...registerField('lastName', { validators: [required] })}
          label="Last Name"
          data-testid="RegistrationForm__LastName"
          readOnly={isSubmitting}
          defaultValue={user.lastName || undefined}
        />
        <TextField
          {...registerField('contactEmail', { validators: [isEmail] })}
          label="Email"
          data-testid="RegistrationForm__Email"
          readOnly={isSubmitting}
          defaultValue={user.contactEmail || undefined}
        />
        <FieldDisclaimer>
          <FormattedField
            {...phoneFormat}
            {...registerField('phoneNumber', {
              dataType: 'numericString',
              validators: [
                exactCharacterLength(10, 'Invalid phone number'),
                isValidPhoneForCountry([
                  Country.UNITED_STATES,
                  Country.CANADA,
                  country as Country,
                ]),
              ],
            })}
            key={`phone_${defaultPhone}`}
            label="Mobile Number"
            data-testid="RegistrationForm__Phone"
            helperText={PHONE_HELPER_TEXT}
            readOnly={isSubmitting}
            defaultValue={defaultPhone || undefined}
          />
        </FieldDisclaimer>
        <TextField
          {...registerField('zipcode', {
            validators: Object.values(zipValidation),
          })}
          {...zipInputProps}
          readOnly={isSubmitting}
          label={zipLabel}
          data-testid="RegistrationForm__ZipCode"
          defaultValue={user.zipcode || undefined}
        />
        {consentDisclosures.map((consentDisclosure, i) => (
          <ConsentDisclosure
            key={`consentCheckbox__${i}`}
            data-testid={`signUpConsent__${i}`}
          >
            <Checkbox
              value="true"
              label=""
              required={true}
              onChange={(e) => updateConsent(e.target)}
              data-testid={`consentCheckbox__${i}`}
            />
            <Disclosure disclosure={consentDisclosure} />
          </ConsentDisclosure>
        ))}
        {optionalDisclosures.map((optionalDisclosure, i) => (
          <ConsentDisclosure
            key={`optionalCheckbox__${i}`}
            data-testid={`signUpOptional__${i}`}
          >
            <Checkbox
              {...registerField(`disclosures[${i}].value`, {
                dataType: 'boolean',
                nullable: false,
              })}
              value="true"
              label=""
              data-testid={`optionalCheckbox__${i}`}
            />
            <Disclosure disclosure={optionalDisclosure} appendOptional={true} />
          </ConsentDisclosure>
        ))}
      </FieldsWrapper>
      {submissionError && (
        <ErrorMessage data-testid="submissionErrorAlert">
          {submissionError}
        </ErrorMessage>
      )}
      <PillButton
        data-testid="RegistrationForm__Continue"
        variant="accent"
        disabled={isSubmitting}
        onClick={onSubmit}
      >
        Continue
      </PillButton>
      <Disclaimer data-testid="RegistrationForm__Disclaimer">
        <AggregatedDisclosure location={DisclosureLocation.REGISTRATION} />
      </Disclaimer>
    </>
  );
};
