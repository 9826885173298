"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPartialDealSheetUpdateFromDealer = void 0;
var getPartialDealSheetUpdateFromDealer = function (dealer) {
    if (!dealer)
        return {};
    return {
        dealerId: dealer.id,
        dealerName: dealer.name,
        externalDealerId: dealer.providerDealerId,
        showTitleFees: !!dealer.showTitleFees,
        showRegistrationFees: !!dealer.showRegistrationFees,
    };
};
exports.getPartialDealSheetUpdateFromDealer = getPartialDealSheetUpdateFromDealer;
