import { ProviderLogo } from '@digital-motors-boatyard/common/dist/enums';

export enum ModalError {
  DEAL_NOT_FOUND = 'deal-not-found',
  REQUEST_UNAVAILABLE = 'request-unavailable',
}

export interface TenantProviderData {
  logo: { url: string; alt: string };
  salesPortalDomain: string;
}

export type TenantProviderDataMap = {
  [key in ProviderLogo]: TenantProviderData;
};
