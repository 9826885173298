import { useTheme } from '@digital-motors-boatyard/ui.theme';
import { css, Global } from '@emotion/react';
import { FC } from 'react';

export const FontStyles: FC = () => {
  const theme = useTheme();
  const fonts = theme.fonts;

  // Added a ternary for the format b/c there is an issue with
  // otf font types not working when the format is specified
  return (
    <Global
      styles={css`
        ${fonts.map((font) => {
          return `@font-face {
              font-family: ${font.fontFamily};
              src: url('${font.src.url}')
              ${
                font.src.format === 'otf'
                  ? ';'
                  : `format('${
                      font.src.format === 'ttf' ? 'truetype' : font.src.format
                    }`
              }');
              font-weight: ${font.weight};
              font-style: ${font.style};
            }`;
        })}
      `}
    />
  );
};
