"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTenant = exports.TenantProvider = exports.DEFAULT_TENANT_CONFIG = void 0;
var constants_1 = require("@digital-motors-boatyard/common/dist/constants");
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
var react_1 = __importStar(require("react"));
exports.DEFAULT_TENANT_CONFIG = {
    id: '',
    createdAt: '',
    updatedAt: '',
    name: '',
    legalName: '',
    host: null,
    defaultLocale: enums_1.Locale.AMERICAN_ENGLISH,
    type: null,
    active: false,
    makes: [],
    hasPreOwnedMakesWhitelist: false,
    banner: {
        url: '',
        alt: '',
    },
    logo: {
        url: '',
        alt: '',
        height: 0,
    },
    analytics: {
        provider: null,
        sid: null,
    },
    dealers: [],
    lenders: {
        ids: [],
        aggregators: [],
        captivePriority: true,
        dealerPriority: true,
    },
    configuration: constants_1.defaultTenantConfiguration,
    defaultSearchRadius: 200000,
    faviconUrl: null,
    contactUs: {
        url: '',
        dealerPriority: false,
    },
    crms: [],
    address1: null,
    address2: null,
    address3: null,
    city: null,
    state: null,
    zip: null,
    salesPhone: null,
    analyticsProviders: [],
    country: enums_1.Country.UNITED_STATES,
    currency: enums_1.Currency.US_DOLLAR,
    tradeIn: {
        dealerPriority: false,
        provider: null,
        providerId: null,
        status: false,
    },
    parallelDealerLeadSubmission: false,
    creditAppProviderConfig: {
        id: '',
        createdAt: '',
        updatedAt: '',
        creditAppProvider: {
            id: '',
            createdAt: '',
            updatedAt: '',
            name: '',
            logo: {
                url: null,
                alt: null,
            },
        },
    },
    disclosures: [],
    vertical: enums_1.Vertical.MARINE,
    enabledVesselClasses: [],
};
var TenantContext = (0, react_1.createContext)(exports.DEFAULT_TENANT_CONFIG);
var TenantProvider = function (_a) {
    var tenant = _a.tenant, children = _a.children;
    return (react_1.default.createElement(TenantContext.Provider, { value: tenant }, children));
};
exports.TenantProvider = TenantProvider;
var useTenant = function () {
    var context = (0, react_1.useContext)(TenantContext);
    if (!context) {
        throw new Error('useTenant must be used inside of a TenantProvider!');
    }
    return context;
};
exports.useTenant = useTenant;
