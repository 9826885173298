// eslint-disable-next-line no-use-before-define
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

const riderElement = document.getElementById('boatyard-rider');

if (riderElement) {
  const root = createRoot(riderElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
