import {
  STATUS_ERROR,
  STATUS_SUCCESS,
} from '@digital-motors-boatyard/common-frontend/dist/constants';
import { Result } from '@digital-motors-boatyard/common-frontend/dist/utility/result';
import jwtDecode from 'jwt-decode';
import { useCallback, useMemo } from 'react';
import * as workerTimers from 'worker-timers';

import { refreshUserToken, TokenData } from '../../../api/refreshUserToken';
import { DEFAULT_TOKEN_INTERVAL_TIME } from '../../../constants';
import { StorageObject, useStorage } from '../../../hooks/useStorage';
import { RefreshTokenJwt } from '../types';
import { setAxiosAuthorizationHeader } from './setAxiosAuthorizationHeader';

const tokenIntervalCallback = async ({
  storage,
  tenantId,
}: {
  storage: StorageObject;
  tenantId: string;
}) => {
  const refreshToken = storage.get(`refreshToken__${tenantId}`);

  if (refreshToken) {
    return refreshUserToken({ refreshToken });
  }
  return { type: STATUS_ERROR } as Result<TokenData>;
};

export const useTokenInterval = (tenantId: string) => {
  const storage = useStorage();

  const stopTokenInterval = useCallback(() => {
    const interval = storage.get(`refreshInterval__${tenantId}`);

    if (!interval) return;

    // Wrap in an empty try+catch to swallow any errors
    try {
      if (workerTimers?.clearInterval) {
        workerTimers.clearInterval(Number(interval));
      } else {
        clearInterval(Number(interval));
      }
    } catch {} // eslint-disable-line no-empty

    storage.delete(`refreshInterval__${tenantId}`);
  }, [storage, tenantId]);

  const startTokenInterval = useCallback(() => {
    const refreshToken = storage.get(`refreshToken__${tenantId}`);
    if (!refreshToken) return;

    const { exp } = (jwtDecode(refreshToken) as RefreshTokenJwt) || {};
    if (!exp) return;

    const intervalHours = Math.floor(
      (exp - new Date().valueOf() / 1000) / 3600
    );
    let intervalTime = intervalHours * 3600;

    // Guard against bad data
    if (!intervalTime || intervalTime < 0) {
      intervalTime = DEFAULT_TOKEN_INTERVAL_TIME;
    }

    // Handle fetching and setting fresh token data
    const intervalAction = async () => {
      const res = await tokenIntervalCallback({ storage, tenantId });

      if (res && res.type === STATUS_SUCCESS) {
        storage.set(`token__${tenantId}`, res.value.token);
        storage.set(`refreshToken__${tenantId}`, res.value.refreshToken, true);
        setAxiosAuthorizationHeader(res.value.token);
      } else {
        storage.delete(`token__${tenantId}`);
        storage.delete(`refreshToken__${tenantId}`);
        storage.set(`logout__${tenantId}`, new Date().valueOf().toString());
        stopTokenInterval();
      }
    };

    // Stop the current interval in case it's still going
    stopTokenInterval();

    // Start a new interval
    const intervalId = workerTimers?.setInterval
      ? String(workerTimers.setInterval(intervalAction, intervalTime))
      : String(setInterval(intervalAction, intervalTime));
    storage.set(`refreshInterval__${tenantId}`, intervalId);

    return intervalId;
  }, [stopTokenInterval, storage, tenantId]);

  return useMemo(
    () => ({ startTokenInterval, stopTokenInterval }),
    [startTokenInterval, stopTokenInterval]
  );
};
