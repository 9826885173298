import {
  LengthUnit,
  VesselClass,
} from '@digital-motors-boatyard/common/dist/apis';
import {
  Condition,
  FinanceType,
} from '@digital-motors-boatyard/common/dist/enums';
import {
  AprTerm,
  CustomModelIncentive,
  DealSheetDealerFeeInterface,
  TenantInterface,
} from '@digital-motors-boatyard/common/dist/interfaces';
import { STATUS_SUCCESS } from '@digital-motors-boatyard/common-frontend/dist/constants';
import { useEffectOnMount } from '@digital-motors-boatyard/common-frontend/dist/hooks';
import { FC, useState } from 'react';
import shortid from 'shortid';

import { createAnonymousUser } from '../../api/createAnonymousUser';
import { getTenant } from '../../api/getTenant';
import { BoatyardDealSheetDefaults } from '../../hooks/useBoatyard';
import { useStorage } from '../../hooks/useStorage';
import { Widget } from './Widget';

interface WidgetData {
  elementId: string;
  defaults: BoatyardDealSheetDefaults;
  tenant: TenantInterface;
}

interface WidgetFetchData extends Omit<WidgetData, 'tenant'> {
  tenantId: string;
}

interface TenantMapping {
  [key: string]: TenantInterface;
}

export const Widgets: FC = () => {
  const storage = useStorage();
  const [widgets, setWidgets] = useState<WidgetData[]>([]);

  useEffectOnMount(() => {
    (async () => {
      const widgetElements = document.getElementsByClassName('rider-widget');
      const widgetsToAdd: WidgetFetchData[] = [];

      if (!widgetElements || !widgetElements.length) return;

      // Collect defaults from widget element data attributes
      [...widgetElements].forEach((element) => {
        const elementId = `rider-widget__${shortid.generate()}`;
        const tenantId = element.getAttribute('data-tenant-id');

        if (!tenantId) return;

        const hin = element.getAttribute('data-hin');
        const stockNumber = element.getAttribute('data-stock-number');
        const customInventoryId = element.getAttribute(
          'data-custom-inventory-id'
        );
        const vesselLength = Number(element.getAttribute('data-vessel-length'));
        const vesselLengthUnit = element.getAttribute(
          'data-vessel-length-unit'
        ) as LengthUnit;
        const engineManufacturer = element.getAttribute(
          'data-engine-manufacturer'
        );
        const totalEngines = Number(element.getAttribute('data-total-engines'));
        const totalHorsepower = Number(
          element.getAttribute('data-total-horsepower')
        );
        const mileage = Number(element.getAttribute('data-mileage'));
        const financeType = element.getAttribute(
          'data-finance-type'
        ) as FinanceType;
        const term = Number(element.getAttribute('data-term')) as AprTerm;
        const downPayment = Number(element.getAttribute('data-down-payment'));
        const creditRating = element.getAttribute('data-credit-rating');
        const returnWebsiteUrl = element.getAttribute(
          'data-return-website-url'
        );

        element.setAttribute('id', elementId);

        const additionalLeadData = element
          .getAttributeNames()
          .filter((key) => key.startsWith('data-lead-callback-'))
          .map((key) => {
            const name = key
              .replace('data-lead-callback-', '')
              .replace(/-./g, (c) => c[1].toUpperCase()); // camel-case name
            const value = element.getAttribute(key) || '';
            return { name, value };
          });

        const dealerFees: DealSheetDealerFeeInterface[] = [];

        const feeAttribs = element
          .getAttributeNames()
          .filter((key) => key.startsWith('data-fee-name-'));

        for (const feeAttrib of feeAttribs) {
          const id = feeAttrib.replace('data-fee-name-', '');
          const name = element.getAttribute(feeAttrib);
          const amount = Number(element.getAttribute(`data-fee-amount-${id}`));
          if (!name || !amount) {
            // Don't display any fee if any of the given fees are not parsable
            dealerFees.length = 0;
            break;
          }
          dealerFees.push({ id, name, amount });
        }

        const customModelIncentives: CustomModelIncentive[] = [];

        const incentiveAttribs = element
          .getAttributeNames()
          .filter((key) => key.startsWith('data-custom-model-incentive-name'));

        for (const feeAttrib of incentiveAttribs) {
          const id = feeAttrib.replace('data-custom-model-incentive-name-', '');
          const name = element.getAttribute(feeAttrib);
          const amount = Number(
            element.getAttribute(`data-custom-model-incentive-amount-${id}`)
          );
          if (!name || !amount) {
            // Don't allow any if any of the given attributes are not parsable.
            customModelIncentives.length = 0;
            break;
          }
          customModelIncentives.push({
            name,
            amount,
          });
        }

        widgetsToAdd.push({
          elementId,
          tenantId,
          defaults: {
            dealerId: element.getAttribute('data-dealer-id') as string,
            condition: element.getAttribute('data-condition') as Condition,
            year: Number(element.getAttribute('data-year')),
            make: element.getAttribute('data-make') as string,
            model: element.getAttribute('data-model') as string,
            oemCode: element.getAttribute('data-oem-code') as string,
            basePrice: Number(element.getAttribute('data-base-price')),
            dealerPrice: Number(element.getAttribute('data-dealer-price')),
            vesselClass: element.getAttribute(
              'data-vessel-class'
            ) as VesselClass,
            imageUrl: element.getAttribute('data-image-url') as string,
            ...(hin ? { hin } : null),
            ...(stockNumber ? { stockNumber } : null),
            ...(customInventoryId ? { customInventoryId } : null),
            ...(vesselLength ? { vesselLength } : null),
            ...(vesselLengthUnit ? { vesselLengthUnit } : null),
            ...(engineManufacturer ? { engineManufacturer } : null),
            ...(totalEngines ? { totalEngines } : null),
            ...(totalHorsepower ? { totalHorsepower } : null),
            ...(mileage ? { mileage } : null),
            ...(financeType ? { financeType } : null),
            ...(term ? { term } : null),
            ...(downPayment ? { downPayment } : null),
            ...(creditRating ? { creditRating } : null),
            ...(returnWebsiteUrl ? { returnWebsiteUrl } : null),
            additionalLeadData,
            dealerFees,
            customModelIncentives,
          },
        });
      });

      // Fetch unique tenants and apply them to the widget data objects
      const uniqueTenantIds = Array.from(
        new Set(widgetsToAdd.map((w) => w.tenantId))
      );
      const tenantResults = await Promise.all(
        uniqueTenantIds.map((tenantId) => getTenant(tenantId))
      );
      const tenants: TenantMapping = {};
      tenantResults.forEach((res) => {
        if (res.type !== STATUS_SUCCESS) return;
        tenants[res.value.id] = res.value;
      });
      const tenantIds = Object.keys(tenants);

      // Fetch tokens as needed for the tenants
      const userResults = await Promise.all(
        tenantIds
          .filter((tenantId) => !storage.get(`refreshToken__${tenantId}`))
          .map((tenantId) => createAnonymousUser(tenantId))
      );
      userResults.forEach((res, idx) => {
        if (res.type !== STATUS_SUCCESS) return;
        storage.set(`token__${tenantIds[idx]}`, res.value.token);
        storage.set(
          `refreshToken__${tenantIds[idx]}`,
          res.value.refreshToken,
          true
        );
      });

      const widgetsWithTenants = widgetsToAdd
        .map((widget) => ({
          elementId: widget.elementId,
          defaults: widget.defaults,
          tenant: tenants[widget.tenantId],
        }))
        .filter((widget) => widget.tenant);

      setWidgets(widgetsWithTenants);
    })();
  });

  return (
    <>
      {widgets.map((props) => (
        <Widget key={props.elementId} {...props} />
      ))}
    </>
  );
};
