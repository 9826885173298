"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertIsoDateToFormat = void 0;
var constants_1 = require("../constants");
var convertIsoDateToFormat = function (isoDate, format) {
    if (!isoDate)
        return undefined;
    if (format === constants_1.DATE_FORMAT) {
        return isoDate.replace(/(\d+)-(\d+)-(\d+).*/, '$2 / $3 / $1');
    }
    if (format === constants_1.DATE_FORMAT_SHORT) {
        return isoDate.replace(/(\d+)-(\d+).*/, '$2 / $1');
    }
    throw new Error('Invalid format given to `convertIsoDateToFormat`');
};
exports.convertIsoDateToFormat = convertIsoDateToFormat;
