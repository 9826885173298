import { Modal, ModalHeader } from '@digital-motors-boatyard/components.modal';
import { Text } from '@digital-motors-boatyard/ui.text';
import { spacing, styled } from '@digital-motors-boatyard/ui.theme';
import { FC } from 'react';

import { color } from '../lib/color';
import { ModalError } from '../types';
import { PillButton } from './PillButton';

const PADDING = spacing.units(6);

interface Props {
  error: ModalError;
  dismiss: () => void;
}

const Description = styled(Text)`
  margin: ${spacing.xs} auto;
`;
Description.defaultProps = {
  as: 'p',
  colorScope: 'sidebars.rider',
  colorKey: 'subtle',
  variant: 'small',
};

const ButtonWrapper = styled.div`
  border-top: 1px solid ${color('divider')};
  padding-top: ${PADDING};
`;

export const ErrorModal: FC<Props> = ({ error, dismiss }) => (
  <Modal
    data-testid="ErrorModal"
    colorScope="sidebars.rider"
    innerPadding={PADDING}
    borderRadius={spacing.xxxs}
    size="m"
    fullScreenMobile
    isCentered
    isOpen
    isInline
  >
    {(() => {
      switch (error) {
        case ModalError.DEAL_NOT_FOUND:
          return (
            <>
              <ModalHeader
                colorScope="sidebars.rider"
                title="Deal Not Found"
                noLine
              />
              <Description>
                We are unable to locate the requested deal at this time. This
                may happen for deals created some time in the past which are no
                longer stored in our system. Please press the button below to
                return to the previous page. Thank you.
              </Description>
            </>
          );
        default:
        case ModalError.REQUEST_UNAVAILABLE:
          return (
            <>
              <ModalHeader
                colorScope="sidebars.rider"
                title="Request Unavailable"
                noLine
              />
              <Description>
                We are unable to configure this unit at this time and are
                working to make it available as soon as possible. Please press
                the button below to return to the previous page. Thank you.
              </Description>
            </>
          );
      }
    })()}
    <ButtonWrapper>
      <PillButton variant="accent" onClick={dismiss}>
        Go Back
      </PillButton>
    </ButtonWrapper>
  </Modal>
);
