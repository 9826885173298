import { DisclosureLocation } from '@digital-motors-boatyard/common/dist/enums';
import { Text } from '@digital-motors-boatyard/ui.text';
import {
  borderRadius,
  spacing,
  styled,
} from '@digital-motors-boatyard/ui.theme';
import { FC } from 'react';

import { color } from '../../lib/color';
import { AggregatedDisclosure } from '../AggregatedDisclosure';

const Disclaimer = styled(Text)`
  background: ${color('divider')};
  color: ${color('subtle')};
  margin: 0;
  padding: ${spacing.xs};
  border-radius: ${borderRadius.s};
  text-align: left;
`;
Disclaimer.defaultProps = {
  as: 'div',
  variant: 'caption',
};

export const ReEntryDisclaimer: FC = () => (
  <Disclaimer data-testid="ReEntry__Disclaimer">
    <AggregatedDisclosure location={DisclosureLocation.CHECKOUT_RIDER} />
  </Disclaimer>
);
