import { useRiderComponent } from '@digital-motors-boatyard/by-vessel-rider.component';
import { Country } from '@digital-motors-boatyard/common/dist/enums';
import { UserInterface } from '@digital-motors-boatyard/common/dist/interfaces';
import { STATUS_SUCCESS } from '@digital-motors-boatyard/common-frontend/dist/constants';
import {
  useEffectOnMount,
  useInterval,
  useIsMounted,
} from '@digital-motors-boatyard/common-frontend/dist/hooks';
import { removeIntlPhonePrefix } from '@digital-motors-boatyard/common-frontend/dist/utility/removeIntlPhonePrefix';
import { FormProvider } from '@digital-motors-boatyard/components.form';
import { Modal, ModalHeader } from '@digital-motors-boatyard/components.modal';
import { Spinner } from '@digital-motors-boatyard/ui.spinner';
import { spacing } from '@digital-motors-boatyard/ui.theme';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { FC, useRef } from 'react';

import { createUser } from '../../api/createUser';
import { saveDealSheet } from '../../api/saveDealSheet';
import { sendPhoneValidation } from '../../api/sendPhoneValidation';
import { updateUser } from '../../api/updateUser';
import { DEFAULT_API_DEBOUNCE_TIME } from '../../constants';
import { useAnalytics } from '../../context/Analytics';
import { useBoatyardAnalyticsParams } from '../../context/Analytics/utils/getParamsFromBoatyard';
import { useAppData } from '../../context/AppDataContext';
import { useUser } from '../../context/User';
import { getUserFromJwt } from '../../context/User/utils/getUserFromJwt';
import { useBoatyard } from '../../hooks/useBoatyard';
import { useStorage } from '../../hooks/useStorage';
import { PhoneAuthForm } from './PhoneAuthForm';
import { RegistrationForm } from './RegistrationForm';
import { SignInForm } from './SignInForm';

interface Props {
  dismiss?: () => void;
  autoRegister?: boolean;
}

export const UserAuthModal: FC<Props> = ({ dismiss, autoRegister }) => {
  const isMounted = useIsMounted();
  const boatyard = useBoatyard();
  const storage = useStorage();
  const { trackEvent } = useAnalytics();
  const analyticsParams = useBoatyardAnalyticsParams();
  const { tenant, dealer } = useAppData();
  const { dealSheet } = useRiderComponent();
  const { id: tenantId, country, configuration } = tenant;
  const { id: dealerId } = dealer;
  const {
    authModalView: view,
    setAuthModalView,
    signInPhone,
    ...user
  } = useUser();

  useInterval(() => {
    if (!window?.boatyard?.vessel) setAuthModalView('hidden');
  }, 600);

  // If we have a user object (which came from the boatyard object),
  // attempt to login/register them automatically
  const autoRegisterBoatyardUser = useRef(
    debounce(async () => {
      if (
        !isMounted() ||
        !autoRegister ||
        !signInPhone ||
        !boatyard?.vessel?.user
      ) {
        return;
      }
      // Clear the user data from the rider object, so we don't
      // trigger another auto-registration when the user logs out
      if (window.boatyard?.vessel?.user) {
        window.boatyard.vessel.user = undefined;
      }

      setAuthModalView('busy');

      // First attempt to log the user in
      const validationRes = await sendPhoneValidation({
        phoneNumber: signInPhone,
        tenantId,
        country,
      });

      if (!isMounted() || !boatyard?.vessel) return;

      // If the request was successful, show the SMS verification
      if (validationRes.success) {
        setAuthModalView('verify');
        return;
      }

      // If no user was found for that phone number, try to
      // sign them up with the data provided
      if (validationRes.errorType === 'notFound') {
        const { firstName, lastName, contactEmail, zipcode } = user;

        if (firstName && lastName && contactEmail && zipcode) {
          const payload = {
            phoneNumber: removeIntlPhonePrefix(user.phoneNumber),
            firstName,
            lastName,
            contactEmail,
            zipcode,
            dealerId,
            country: country as Country,
          };
          const createRes = await (user.isAuthenticated
            ? updateUser({ ...payload, id: user.id })
            : createUser({ ...payload, tenantId }));

          if (!isMounted() || !boatyard?.vessel) return;

          if (createRes.type === STATUS_SUCCESS) {
            trackEvent('rider_signup_silent', {
              user_zip: zipcode,
              ...analyticsParams,
            });
            // If the tenant does not require phone verification to sign up,
            // authenticate them immediately
            if (!configuration?.web.global.requiresPhoneAuthentication) {
              const { token } = createRes.value;
              const refreshToken = get(createRes, 'value.refreshToken');
              const userData = token && getUserFromJwt(token);

              if (token && userData && userData.id) {
                storage.set(`token__${tenantId}`, token);
                if (refreshToken) {
                  storage.set(`refreshToken__${tenantId}`, refreshToken, true);
                }
                await user.authenticateUser(
                  userData,
                  false,
                  dealSheet?.id
                    ? async () => {
                        // Make a PATCH request again with the new JWT which causes
                        // the BE dealsheet to update with the full user object
                        await saveDealSheet({
                          id: dealSheet.id,
                          userId: user.id,
                        });
                      }
                    : undefined
                );
                return;
              }
            }
            // Otherwise, send a code and switch to the verification form
            sendPhoneValidation({
              phoneNumber: signInPhone,
              tenantId,
              country,
            });
            setAuthModalView('verify');
            return;
          }
        }
        trackEvent('rider_signup_silent_failed', {
          user_zip: zipcode,
          ...analyticsParams,
        });
      }

      // Otherwise show the registration form so the user can fix
      // any missing or invalid data
      setAuthModalView('register');
    }, DEFAULT_API_DEBOUNCE_TIME)
  ).current;

  useEffectOnMount(() => {
    if (autoRegister) {
      setAuthModalView(user.phoneAuthenticated ? 'busy' : 'signin');
      autoRegisterBoatyardUser();
    }
  });

  return (
    <Modal
      colorScope="sidebars.rider"
      innerPadding={spacing.xs}
      borderRadius={spacing.xxxs}
      size="m"
      data-testid="UserAuthModal__Header"
      fullScreenMobile
      isCentered
      isInline
      isOpen={view !== 'hidden'}
    >
      {view === 'busy' && (
        <>
          <ModalHeader
            colorScope="sidebars.rider"
            title={user.firstName ? `Welcome, ${user.firstName}` : 'Welcome'}
            leftButtonText="Cancel"
            leftButtonAction={dismiss || boatyard?.closeRider}
            noLine
          />
          <Spinner size={48} />
        </>
      )}
      <div hidden={view === 'signin' ? undefined : true}>
        <FormProvider<{ phoneNumber: string }> key="signin">
          <SignInForm visible={view === 'signin'} dismiss={dismiss} />
        </FormProvider>
      </div>
      <div hidden={view === 'register' ? undefined : true}>
        <FormProvider<UserInterface> key="register">
          <RegistrationForm visible={view === 'register'} dismiss={dismiss} />
        </FormProvider>
      </div>
      <div hidden={view === 'verify' ? undefined : true}>
        <FormProvider<{ code: string }> key="verify">
          <PhoneAuthForm
            onClickBack={() => setAuthModalView('signin')}
            onSuccess={() => setAuthModalView('hidden')}
            phoneNumber={signInPhone}
            visible={view === 'verify'}
            eventParams={analyticsParams}
          />
        </FormProvider>
      </div>
    </Modal>
  );
};
