import { DealSheet } from '@digital-motors-boatyard/common/dist/interfaces';
import { STATUS_SUCCESS } from '@digital-motors-boatyard/common-frontend/dist/constants';
import { diffObjects } from '@digital-motors-boatyard/common-frontend/dist/utility/diffObjects';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { createDealSheet } from '../../../api/createDealSheet';
import { getDealSheet } from '../../../api/getDealSheet';
import { saveDealSheet } from '../../../api/saveDealSheet';

export const getOrCreateDealSheet = async (
  dealSheet: Partial<DealSheet>
): Promise<DealSheet | null> => {
  if (dealSheet.id) {
    const dealSheetRes = await getDealSheet(dealSheet.id);

    return dealSheetRes.type === STATUS_SUCCESS ? dealSheetRes.value : null;
  }

  const newDealSheet = omit(dealSheet, 'id');
  const createRes = await createDealSheet(newDealSheet);

  if (createRes.type === STATUS_SUCCESS && createRes.value.id) {
    const getRes = await getDealSheet(createRes.value.id);

    if (getRes.type === STATUS_SUCCESS) {
      // Patch any values that weren't added in the intial POST
      const diffKeys = Object.keys(
        diffObjects(getRes.value, { ...getRes.value, ...newDealSheet })
      );
      const payload = pick(newDealSheet, diffKeys);

      if (Object.keys(payload).length) {
        await saveDealSheet({ ...payload, id: createRes.value.id });
      }
      return { ...newDealSheet, ...getRes.value };
    }
  }

  return null;
};
