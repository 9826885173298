"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollUpOnMount = void 0;
var hooks_1 = require("@digital-motors-boatyard/common-frontend/dist/hooks");
var useScrollUpOnMount = function () {
    (0, hooks_1.useEffectOnMount)(function () {
        var riderContainer = document.getElementById('boatyard-rider-container');
        if (riderContainer === null || riderContainer === void 0 ? void 0 : riderContainer.scrollTo)
            riderContainer.scrollTo(0, 0);
    });
};
exports.useScrollUpOnMount = useScrollUpOnMount;
