"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPlanConditionForFniProduct = void 0;
var getPlanConditionForFniProduct = function (fniProduct, conditions) {
    if (fniProduct) {
        var selectedCondition = conditions.find(function (condition) {
            var _a, _b;
            return (fniProduct.price === ((condition === null || condition === void 0 ? void 0 : condition.amount) || 0) &&
                (!fniProduct.lengthRangeStart ||
                    fniProduct.lengthRangeStart.value ===
                        ((_a = condition === null || condition === void 0 ? void 0 : condition.lengthRangeStart) === null || _a === void 0 ? void 0 : _a.value)) &&
                (!fniProduct.lengthRangeEnd ||
                    fniProduct.lengthRangeEnd.value ===
                        ((_b = condition === null || condition === void 0 ? void 0 : condition.lengthRangeEnd) === null || _b === void 0 ? void 0 : _b.value)) &&
                (fniProduct.horsepowerRangeStart || 0) ===
                    ((condition === null || condition === void 0 ? void 0 : condition.horsepowerRangeStart) || 0) &&
                (fniProduct.horsepowerRangeEnd || 0) ===
                    ((condition === null || condition === void 0 ? void 0 : condition.horsepowerRangeEnd) || 0) &&
                (fniProduct.vesselClass || null) === ((condition === null || condition === void 0 ? void 0 : condition.vesselClass) || null));
        });
        if (selectedCondition)
            return selectedCondition;
    }
    return conditions.sort(function (a, b) { return (a.amount > b.amount ? 1 : -1); })[0];
};
exports.getPlanConditionForFniProduct = getPlanConditionForFniProduct;
