"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SevenHundredCreditPolicyAndConditions = void 0;
var getTenantDisclosures_1 = require("@digital-motors-boatyard/common/dist/helpers/getTenantDisclosures");
var components_modal_1 = require("@digital-motors-boatyard/components.modal");
var ui_button_1 = require("@digital-motors-boatyard/ui.button");
var ui_helpers_1 = require("@digital-motors-boatyard/ui.helpers");
var ui_icons_1 = require("@digital-motors-boatyard/ui.icons");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var react_1 = __importStar(require("react"));
var constants_1 = require("./constants");
var Wrapper = ui_theme_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  p {\n    margin: 0;\n    padding: 0;\n    text-align: left;\n  }\n  li {\n    text-align: left;\n  }\n  a {\n    &,\n    :visited,\n    :active {\n      color: inherit;\n    }\n    :hover,\n    :focus {\n      color: ", ";\n    }\n  }\n\n  display: grid;\n  grid-template-columns: 50% 50%;\n  text-align: center;\n"], ["\n  p {\n    margin: 0;\n    padding: 0;\n    text-align: left;\n  }\n  li {\n    text-align: left;\n  }\n  a {\n    &,\n    :visited,\n    :active {\n      color: inherit;\n    }\n    :hover,\n    :focus {\n      color: ", ";\n    }\n  }\n\n  display: grid;\n  grid-template-columns: 50% 50%;\n  text-align: center;\n"])), (0, ui_helpers_1.color)('regular'));
var PriavcyPolicyModal = function (_a) {
    var tenant = _a.tenant, dealer = _a.dealer, dealSheet = _a.dealSheet, isOpen = _a.isOpen, onClose = _a.onClose;
    return (react_1.default.createElement(components_modal_1.Modal, { colorScope: "sidebars.rider", innerPadding: ui_theme_1.spacing.xs, borderRadius: ui_theme_1.spacing.xxxs, size: "l", "data-testid": "PrivacyPolicy__Modal", fullScreenMobile: true, isCentered: true, isInline: true, isOpen: isOpen },
        react_1.default.createElement(components_modal_1.ModalHeader, { colorScope: "sidebars.rider", title: "Privacy Policy", leftButton: react_1.default.createElement(ui_button_1.Fab, { "aria-label": "Close Privacy Policy", "data-testid": "PrivacyPolicy__Modal_Close", onClick: onClose, variant: "secondary", size: "s", rounded: true },
                react_1.default.createElement(ui_icons_1.Close, null)), noLine: true }),
        react_1.default.createElement("div", { "data-testid": "PrivacyPolicy", dangerouslySetInnerHTML: {
                __html: filterXSS((0, getTenantDisclosures_1.formatDisclosureText)([constants_1.PRIACY_POLICY], tenant, dealer, dealSheet)[0]),
            } })));
};
var TermsAndConditionsModal = function (_a) {
    var tenant = _a.tenant, dealer = _a.dealer, dealSheet = _a.dealSheet, isOpen = _a.isOpen, onClose = _a.onClose;
    return (react_1.default.createElement(components_modal_1.Modal, { colorScope: "sidebars.rider", innerPadding: ui_theme_1.spacing.xs, borderRadius: ui_theme_1.spacing.xxxs, size: "l", "data-testid": "TermsAndConditions__Modal", fullScreenMobile: true, isCentered: true, isInline: true, isOpen: isOpen },
        react_1.default.createElement(components_modal_1.ModalHeader, { colorScope: "sidebars.rider", title: "Terms and Conditions", leftButton: react_1.default.createElement(ui_button_1.Fab, { "aria-label": "Close Terms and Conditions", "data-testid": "TermsAndConditions__Modal_Close", onClick: onClose, variant: "secondary", size: "s", rounded: true },
                react_1.default.createElement(ui_icons_1.Close, null)), noLine: true }),
        react_1.default.createElement("div", { "data-testid": "TermsAndConditions", dangerouslySetInnerHTML: {
                __html: filterXSS((0, getTenantDisclosures_1.formatDisclosureText)([constants_1.TERMS_AND_CONDITIONS], tenant, dealer, dealSheet)[0]),
            } })));
};
var PrivacyPolicy = function (_a) {
    var tenant = _a.tenant, dealer = _a.dealer, dealSheet = _a.dealSheet;
    var _b = (0, react_1.useState)(false), isOpen = _b[0], setIsOpen = _b[1];
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(PriavcyPolicyModal, { tenant: tenant, dealer: dealer, dealSheet: dealSheet, isOpen: isOpen, onClose: function () { return setIsOpen(false); } }),
        react_1.default.createElement("a", { "data-testid": "PrivacyPolicy__Link", href: "#", onClick: function (event) {
                event.preventDefault();
                setIsOpen(true);
            } }, "Privacy Policy")));
};
var TermsAndConditions = function (_a) {
    var tenant = _a.tenant, dealer = _a.dealer, dealSheet = _a.dealSheet;
    var _b = (0, react_1.useState)(false), isOpen = _b[0], setIsOpen = _b[1];
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(TermsAndConditionsModal, { tenant: tenant, dealer: dealer, dealSheet: dealSheet, isOpen: isOpen, onClose: function () { return setIsOpen(false); } }),
        react_1.default.createElement("a", { "data-testid": "TermsAndConditions__Link", href: "#", onClick: function (event) {
                event.preventDefault();
                setIsOpen(true);
            } }, "Terms and Conditions")));
};
var SevenHundredCreditPolicyAndConditions = function (_a) {
    var tenant = _a.tenant, dealer = _a.dealer, dealSheet = _a.dealSheet;
    return (react_1.default.createElement(Wrapper, null,
        react_1.default.createElement(PrivacyPolicy, { tenant: tenant, dealer: dealer, dealSheet: dealSheet }),
        react_1.default.createElement(TermsAndConditions, { tenant: tenant, dealer: dealer, dealSheet: dealSheet })));
};
exports.SevenHundredCreditPolicyAndConditions = SevenHundredCreditPolicyAndConditions;
var templateObject_1;
