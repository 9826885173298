import { color } from '@digital-motors-boatyard/ui.helpers';
import { styled } from '@digital-motors-boatyard/ui.theme';

export const Wrapper = styled.div<{ colorScope?: string }>`
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: underline;
    &,
    :visited,
    :active {
      color: inherit;
    }
    :hover,
    :focus {
      color: ${(props) =>
        color('regular', props.colorScope || 'mainArea.body')(props)};
    }
  }
`;
