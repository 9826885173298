"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.protectionPlanToDealSheetFniProduct = void 0;
var constants_1 = require("../../../constants");
var protectionPlanToDealSheetFniProduct = function (plan, condition, monthlyCost) { return ({
    protectionPlanId: plan.id,
    name: plan.name,
    price: (condition === null || condition === void 0 ? void 0 : condition.totalRetailPrice) || (condition === null || condition === void 0 ? void 0 : condition.amount) || 0,
    details: plan.description || '',
    images: [plan.image],
    mileage: null,
    types: plan.type,
    pricingType: plan.pricingType,
    monthlyCost: monthlyCost,
    markupAmount: Number(plan.markupAmount) || null,
    markupUnit: plan.markupUnit,
    deductibleAmount: plan.deductibleAmount || null,
    dealerCost: (condition === null || condition === void 0 ? void 0 : condition.dealerCost) || null,
    vesselClass: (condition === null || condition === void 0 ? void 0 : condition.vesselClass) || null,
    lengthRangeStart: (condition === null || condition === void 0 ? void 0 : condition.lengthRangeStart) || constants_1.NULL_UNIT_VALUE,
    lengthRangeEnd: (condition === null || condition === void 0 ? void 0 : condition.lengthRangeEnd) || constants_1.NULL_UNIT_VALUE,
    horsepowerRangeStart: (condition === null || condition === void 0 ? void 0 : condition.horsepowerRangeStart) || null,
    horsepowerRangeEnd: (condition === null || condition === void 0 ? void 0 : condition.horsepowerRangeEnd) || null,
    providerName: plan.providerName || null,
}); };
exports.protectionPlanToDealSheetFniProduct = protectionPlanToDealSheetFniProduct;
