"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResidenceLandlordNameField = exports.ResidenceMonthlyPaymentField = exports.ResidenceMoveInDateField = exports.ResidencePostalCodeField = exports.ResidenceRegionField = exports.ResidenceCityField = exports.ResidenceAddressLine2Field = exports.ResidenceAddressLine1Field = exports.ResidenceStatusField = void 0;
var components_form_1 = require("@digital-motors-boatyard/components.form");
var ui_input_1 = require("@digital-motors-boatyard/ui.input");
var react_1 = __importStar(require("react"));
var constants_1 = require("../../constants");
var useCountryProperties_1 = require("../../hooks/useCountryProperties");
var convertIsoDateToFormat_1 = require("../../lib/convertIsoDateToFormat");
var creditApplicantUtils_1 = require("../../lib/creditApplicantUtils");
var PillButton_1 = require("../PillButton");
var ResidenceStatus;
(function (ResidenceStatus) {
    ResidenceStatus["RENT"] = "Rent";
    ResidenceStatus["OWN"] = "Own";
})(ResidenceStatus || (ResidenceStatus = {}));
var ResidenceStatusField = function (_a) {
    var _b, _c, _d;
    var id = _a.id, registerField = _a.registerField, isInverted = _a.isInverted, dealSheet = _a.dealSheet, _e = _a.sectionIndex, sectionIndex = _e === void 0 ? 0 : _e, isCoApp = _a.isCoApp;
    var _f = (0, react_1.useState)(((_d = (_c = (_b = (0, creditApplicantUtils_1.getApplicant)(dealSheet, isCoApp)) === null || _b === void 0 ? void 0 : _b.residenceInformation) === null || _c === void 0 ? void 0 : _c[sectionIndex]) === null || _d === void 0 ? void 0 : _d.residenceStatus) || ResidenceStatus.RENT), status = _f[0], setStatus = _f[1];
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(PillButton_1.PillButtonGroup, null,
            react_1.default.createElement(PillButton_1.PillButton, { "data-testid": "ResidenceFields__Rent__".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), "__").concat(sectionIndex), active: status === ResidenceStatus.RENT, inverted: isInverted, onClick: function () { return setStatus(ResidenceStatus.RENT); } }, "Rent"),
            react_1.default.createElement(PillButton_1.PillButton, { "data-testid": "ResidenceFields__Own__".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), "__").concat(sectionIndex), active: status === ResidenceStatus.OWN, inverted: isInverted, onClick: function () { return setStatus(ResidenceStatus.OWN); } }, "Own")),
        react_1.default.createElement("input", __assign({ id: id }, registerField("".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), ".residenceInformation[").concat(sectionIndex, "].residenceStatus")), { type: "hidden", "data-testid": "ResidenceFields__ResidenceStatus__".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), "__").concat(sectionIndex), value: status }))));
};
exports.ResidenceStatusField = ResidenceStatusField;
var ResidenceAddressLine1Field = function (_a) {
    var _b, _c, _d, _e;
    var id = _a.id, registerField = _a.registerField, isInverted = _a.isInverted, dealSheet = _a.dealSheet, _f = _a.sectionIndex, sectionIndex = _f === void 0 ? 0 : _f, isCoApp = _a.isCoApp;
    return (react_1.default.createElement(components_form_1.TextField, __assign({ id: id }, registerField("".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), ".residenceInformation[").concat(sectionIndex, "].address.addressLine1"), {
        validators: [
            components_form_1.required,
            (0, components_form_1.mustContain)(/[a-z0-9]/i, 'Must contain at least one letter or number'),
        ],
    }), { "data-testid": "ResidenceFields__Street1__".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), "__").concat(sectionIndex), label: "Street Address", inverted: isInverted, defaultValue: (_e = (_d = (_c = (_b = (0, creditApplicantUtils_1.getApplicant)(dealSheet, isCoApp)) === null || _b === void 0 ? void 0 : _b.residenceInformation) === null || _c === void 0 ? void 0 : _c[sectionIndex]) === null || _d === void 0 ? void 0 : _d.address) === null || _e === void 0 ? void 0 : _e.addressLine1 })));
};
exports.ResidenceAddressLine1Field = ResidenceAddressLine1Field;
var ResidenceAddressLine2Field = function (_a) {
    var _b, _c, _d, _e;
    var id = _a.id, registerField = _a.registerField, isInverted = _a.isInverted, dealSheet = _a.dealSheet, _f = _a.sectionIndex, sectionIndex = _f === void 0 ? 0 : _f, isCoApp = _a.isCoApp;
    return (react_1.default.createElement(components_form_1.TextField, __assign({ id: id }, registerField("".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), ".residenceInformation[").concat(sectionIndex, "].address.addressLine2")), { "data-testid": "ResidenceFields__Street2__".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), "__").concat(sectionIndex), label: "Address 2", inverted: isInverted, defaultValue: (_e = (_d = (_c = (_b = (0, creditApplicantUtils_1.getApplicant)(dealSheet, isCoApp)) === null || _b === void 0 ? void 0 : _b.residenceInformation) === null || _c === void 0 ? void 0 : _c[sectionIndex]) === null || _d === void 0 ? void 0 : _d.address) === null || _e === void 0 ? void 0 : _e.addressLine2 })));
};
exports.ResidenceAddressLine2Field = ResidenceAddressLine2Field;
var ResidenceCityField = function (_a) {
    var _b, _c, _d, _e;
    var id = _a.id, registerField = _a.registerField, isInverted = _a.isInverted, dealSheet = _a.dealSheet, _f = _a.sectionIndex, sectionIndex = _f === void 0 ? 0 : _f, isCoApp = _a.isCoApp;
    return (react_1.default.createElement(components_form_1.TextField, __assign({ id: id }, registerField("".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), ".residenceInformation[").concat(sectionIndex, "].address.city"), {
        validators: [
            components_form_1.required,
            (0, components_form_1.mustContain)(/[a-z0-9]/i, 'Must contain at least one letter or number'),
        ],
    }), { "data-testid": "ResidenceFields__City__".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), "__").concat(sectionIndex), label: "City", inverted: isInverted, defaultValue: (_e = (_d = (_c = (_b = (0, creditApplicantUtils_1.getApplicant)(dealSheet, isCoApp)) === null || _b === void 0 ? void 0 : _b.residenceInformation) === null || _c === void 0 ? void 0 : _c[sectionIndex]) === null || _d === void 0 ? void 0 : _d.address) === null || _e === void 0 ? void 0 : _e.city })));
};
exports.ResidenceCityField = ResidenceCityField;
var ResidenceRegionField = function (_a) {
    var _b, _c, _d, _e;
    var id = _a.id, registerField = _a.registerField, isInverted = _a.isInverted, dealSheet = _a.dealSheet, _f = _a.sectionIndex, sectionIndex = _f === void 0 ? 0 : _f, isCoApp = _a.isCoApp;
    var _g = (0, useCountryProperties_1.useCountryProperties)(), stateLabel = _g.stateLabel, stateEnum = _g.stateEnum;
    return (react_1.default.createElement(components_form_1.SelectField, __assign({ id: id }, registerField("".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), ".residenceInformation[").concat(sectionIndex, "].address.region"), {
        validators: [components_form_1.required],
        validateOnChange: true,
    }), { "data-testid": "ResidenceFields__SelectState__".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), "__").concat(sectionIndex), label: stateLabel, inverted: isInverted, defaultValue: (_e = (_d = (_c = (_b = (0, creditApplicantUtils_1.getApplicant)(dealSheet, isCoApp)) === null || _b === void 0 ? void 0 : _b.residenceInformation) === null || _c === void 0 ? void 0 : _c[sectionIndex]) === null || _d === void 0 ? void 0 : _d.address) === null || _e === void 0 ? void 0 : _e.region }),
        react_1.default.createElement("option", { value: "" }),
        Object.values(stateEnum).map(function (state) { return (react_1.default.createElement("option", { key: state, value: state }, state)); })));
};
exports.ResidenceRegionField = ResidenceRegionField;
var ResidencePostalCodeField = function (_a) {
    var _b, _c, _d, _e;
    var id = _a.id, registerField = _a.registerField, isInverted = _a.isInverted, dealSheet = _a.dealSheet, _f = _a.sectionIndex, sectionIndex = _f === void 0 ? 0 : _f, isCoApp = _a.isCoApp;
    var _g = (0, useCountryProperties_1.useCountryProperties)(), zipInputProps = _g.zipInputProps, zipLabel = _g.zipLabel, zipValidation = _g.zipValidation;
    return (react_1.default.createElement(components_form_1.TextField, __assign({ id: id }, registerField("".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), ".residenceInformation[").concat(sectionIndex, "].address.postalCode"), {
        validators: Object.values(zipValidation),
    }), zipInputProps, { "data-testid": "ResidenceFields__Zip__".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), "__").concat(sectionIndex), label: zipLabel, inverted: isInverted, defaultValue: (_e = (_d = (_c = (_b = (0, creditApplicantUtils_1.getApplicant)(dealSheet, isCoApp)) === null || _b === void 0 ? void 0 : _b.residenceInformation) === null || _c === void 0 ? void 0 : _c[sectionIndex]) === null || _d === void 0 ? void 0 : _d.address) === null || _e === void 0 ? void 0 : _e.postalCode })));
};
exports.ResidencePostalCodeField = ResidencePostalCodeField;
var ResidenceMoveInDateField = function (_a) {
    var _b, _c, _d;
    var id = _a.id, registerField = _a.registerField, isInverted = _a.isInverted, dealSheet = _a.dealSheet, _e = _a.sectionIndex, sectionIndex = _e === void 0 ? 0 : _e, isCoApp = _a.isCoApp;
    return (react_1.default.createElement(components_form_1.FormattedField, __assign({ id: id }, ui_input_1.shortDateFormat, registerField("".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), ".residenceInformation[").concat(sectionIndex, "].moveInDate"), {
        dataType: 'isoDate',
        dataFormat: constants_1.DATE_FORMAT_SHORT,
        validators: [(0, components_form_1.isDate)(constants_1.ISO_DATE_FORMAT), (0, components_form_1.inThePast)(constants_1.ISO_DATE_FORMAT)],
    }), { "data-testid": "ResidenceFields__MoveInDate__".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), "__").concat(sectionIndex), label: "Move-In Date", inverted: isInverted, defaultValue: (0, convertIsoDateToFormat_1.convertIsoDateToFormat)((_d = (_c = (_b = (0, creditApplicantUtils_1.getApplicant)(dealSheet, isCoApp)) === null || _b === void 0 ? void 0 : _b.residenceInformation) === null || _c === void 0 ? void 0 : _c[sectionIndex]) === null || _d === void 0 ? void 0 : _d.moveInDate, constants_1.DATE_FORMAT_SHORT) })));
};
exports.ResidenceMoveInDateField = ResidenceMoveInDateField;
var ResidenceMonthlyPaymentField = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var id = _a.id, registerField = _a.registerField, isInverted = _a.isInverted, dealSheet = _a.dealSheet, _h = _a.sectionIndex, sectionIndex = _h === void 0 ? 0 : _h, isCoApp = _a.isCoApp;
    return (react_1.default.createElement(components_form_1.CurrencyField, __assign({ id: id }, registerField("".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), ".residenceInformation[").concat(sectionIndex, "].monthlyPayment"), {
        dataType: 'number',
        validators: [
            components_form_1.required,
            (0, components_form_1.inNumericRange)(0, 99999, 'Must be between $0 and $100,000'),
        ],
    }), { "data-testid": "ResidenceFields__MonthlyPayment__".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), "__").concat(sectionIndex), label: "Monthly Payment", inverted: isInverted, defaultValue: ((_d = (_c = (_b = (0, creditApplicantUtils_1.getApplicant)(dealSheet, isCoApp)) === null || _b === void 0 ? void 0 : _b.residenceInformation) === null || _c === void 0 ? void 0 : _c[sectionIndex]) === null || _d === void 0 ? void 0 : _d.monthlyPayment)
            ? String((_g = (_f = (_e = (0, creditApplicantUtils_1.getApplicant)(dealSheet, isCoApp)) === null || _e === void 0 ? void 0 : _e.residenceInformation) === null || _f === void 0 ? void 0 : _f[sectionIndex]) === null || _g === void 0 ? void 0 : _g.monthlyPayment)
            : undefined })));
};
exports.ResidenceMonthlyPaymentField = ResidenceMonthlyPaymentField;
var ResidenceLandlordNameField = function (_a) {
    var _b, _c, _d;
    var id = _a.id, registerField = _a.registerField, isInverted = _a.isInverted, dealSheet = _a.dealSheet, _e = _a.sectionIndex, sectionIndex = _e === void 0 ? 0 : _e, isCoApp = _a.isCoApp;
    // TODO only show if ResidenceStatus === RENT, probably either need a
    // form-field watcher or a new context for this (and similar fields to come)
    return (react_1.default.createElement(components_form_1.TextField, __assign({ id: id }, registerField("".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), ".residenceInformation[").concat(sectionIndex, "].landlordName"), {
        validators: [(0, components_form_1.minCharacterLength)(2)],
    }), { "data-testid": "ResidenceFields__Landlord__".concat((0, creditApplicantUtils_1.getApplicantLabel)(isCoApp), "__").concat(sectionIndex), label: "Landlord", inverted: isInverted, defaultValue: (_d = (_c = (_b = (0, creditApplicantUtils_1.getApplicant)(dealSheet, isCoApp)) === null || _b === void 0 ? void 0 : _b.residenceInformation) === null || _c === void 0 ? void 0 : _c[sectionIndex]) === null || _d === void 0 ? void 0 : _d.landlordName }))
    // <input
    //   {...registerField(`applicant.residenceInformation[${sectionIndex}].landlordName`)}
    //   data-testid={`ResidenceFields__LandlordName__${sectionIndex}`}
    //   type="hidden"
    //   value=""
    // />
    );
};
exports.ResidenceLandlordNameField = ResidenceLandlordNameField;
