"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradeInPhotos = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var react_1 = __importStar(require("react"));
var Analytics_1 = require("../../context/Analytics");
var VgsForm_1 = require("../../context/VgsForm");
var color_1 = require("../../lib/color");
var ImageUpload_1 = require("../ImageUpload");
var TradeInSummary_1 = require("../trade-in/TradeInSummary");
var Container = ui_theme_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])));
var SummaryBox = ui_theme_1.styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-self: stretch;\n  background: ", ";\n  border-radius: ", ";\n  padding: ", ";\n  margin: 0 0 ", ";\n\n  > div {\n    margin: 0 !important;\n  }\n"], ["\n  align-self: stretch;\n  background: ", ";\n  border-radius: ", ";\n  padding: ", ";\n  margin: 0 0 ", ";\n\n  > div {\n    margin: 0 !important;\n  }\n"])), (0, color_1.color)('bg05'), ui_theme_1.borderRadius.s, ui_theme_1.spacing.xs, ui_theme_1.spacing.xs);
var TradeInPhotos = function () {
    var trackEvent = (0, Analytics_1.useAnalytics)().trackEvent;
    var onUpload = (0, react_1.useCallback)(function () {
        trackEvent('rider_trade_in_photos_add', {
            location: 'next_steps_trade_in',
        });
    }, [trackEvent]);
    return (react_1.default.createElement(Container, null,
        react_1.default.createElement(SummaryBox, null,
            react_1.default.createElement(TradeInSummary_1.TradeInSummary, null)),
        react_1.default.createElement(VgsForm_1.VgsFormProvider, null,
            react_1.default.createElement(ImageUpload_1.ImageUpload, { name: "trade-in", documentType: enums_1.DealSheetDocumentType.TRADE_IN, label: "Trade-In Photo", onUpload: onUpload, allowMultiple: true }))));
};
exports.TradeInPhotos = TradeInPhotos;
var templateObject_1, templateObject_2;
