"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecondaryCtaButton = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
var react_1 = __importStar(require("react"));
var Analytics_1 = require("../context/Analytics");
var DealSheet_1 = require("../context/DealSheet");
var Tenant_1 = require("../context/Tenant");
var useIsInverted_1 = require("../hooks/useIsInverted");
var PillButton_1 = require("./PillButton");
var SecondaryCtaButton = function () {
    var _a;
    var configuration = (0, Tenant_1.useTenant)().configuration;
    var dealSheet = (0, DealSheet_1.useDealSheet)().dealSheet;
    var trackEvent = (0, Analytics_1.useAnalytics)().trackEvent;
    var isInverted = (0, useIsInverted_1.useIsInverted)();
    var isEnabled = (0, react_1.useMemo)(function () {
        var _a, _b;
        return !!((_a = configuration === null || configuration === void 0 ? void 0 : configuration.secondaryCallToActionButton) === null || _a === void 0 ? void 0 : _a.text) &&
            !!((_b = configuration === null || configuration === void 0 ? void 0 : configuration.secondaryCallToActionButton) === null || _b === void 0 ? void 0 : _b.url);
    }, [configuration]);
    var onClick = (0, react_1.useCallback)(function () {
        var _a = (configuration === null || configuration === void 0 ? void 0 : configuration.secondaryCallToActionButton) || {}, url = _a.url, type = _a.type;
        if (url) {
            var params = type === enums_1.CallToActionType.YAMAHA_RESERVE ? "?dsid=".concat(dealSheet.id) : '';
            var fullUrl = url + params;
            window.open(fullUrl, '_blank');
            trackEvent('vdp_secondary_cta');
        }
    }, [configuration, dealSheet.id, trackEvent]);
    return isEnabled ? (react_1.default.createElement(PillButton_1.PillButton, { "data-testid": "SecondaryCtaButton", variant: "ghost", inverted: isInverted, size: "m", onClick: onClick }, (_a = configuration === null || configuration === void 0 ? void 0 : configuration.secondaryCallToActionButton) === null || _a === void 0 ? void 0 : _a.text)) : null;
};
exports.SecondaryCtaButton = SecondaryCtaButton;
